const context = new AudioContext()

export function playNotification () {
  const successNoise = context.createOscillator()
  successNoise.frequency.value = 440
  successNoise.type = 'sine'

  successNoise.frequency.exponentialRampToValueAtTime(
    554.37,
    context.currentTime + 0.15
  )
  successNoise.frequency.exponentialRampToValueAtTime(
    659.25,
    context.currentTime + 0.25
  )

  const successFilter = context.createBiquadFilter()
  successFilter.type = 'bandpass'
  successFilter.Q.value = 0.01

  successNoise
    .connect(successFilter)
    .connect(context.destination)
  successNoise.start()
  successNoise.stop(context.currentTime + 0.5)
}
