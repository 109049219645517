









import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
  components: {
  }
})
export default class ProfileStatusBadge extends Vue {
  @Prop({ default: 'E', required: true }) private status!: string;
}
