


























import { Component, Emit, Vue, Prop } from 'vue-property-decorator'
import { Trash2Icon } from 'vue-feather-icons'
import Image from '@/types/Image'
@Component({
  components: {
    Trash2Icon
  }
})
export default class ImageViewer extends Vue {
  @Prop({ default: [], required: true }) private images!: Image[];
  @Prop({ default: [], required: true }) private loading!: boolean;
  $refs!: {
    canvasTag: HTMLCanvasElement;
  }

  async mounted () {
    // console.log(`Passed images: ${this.images.length}`)
    // console.log(this.images)
  }

  @Emit('delete')
  requestDelete (id: number) {
    return id
  }

  HTMLCanvasElementToBlobURL (canvas: HTMLCanvasElement) {
    return new Promise((resolve) => {
      canvas.toBlob(function (blob) {
        resolve(URL.createObjectURL(blob))
        // URL.revokeObjectURL(url)
      })
    })
  }

  loadURLToHTMLImageElement (url: string, elem: HTMLImageElement) {
    return new Promise((resolve, reject) => {
      elem.onload = () => resolve(elem)
      elem.onerror = reject
      elem.src = url
    })
  }

  get apiBaseURI () {
    return this.$store.getters.getApiBaseURI
  }
}
