


























import { Component, Vue } from 'vue-property-decorator'
// import { EditIcon } from 'vue-feather-icons'
import TopNav from '@/components/TopNav.vue'
import SideNav from '@/components/SideNav.vue'
import Footer from '@/components/Footer.vue'

@Component({
  components: {
    TopNav,
    SideNav,
    Footer
  }
})
export default class PageError extends Vue {
}
