









































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { EditIcon, Trash2Icon, PlusSquareIcon } from 'vue-feather-icons'
import Category from '@/types/Category'
import TopNav from '@/components/TopNav.vue'
import SideNav from '@/components/SideNav.vue'
import Footer from '@/components/Footer.vue'

@Component<CategoryEditor>({
  components: {
    EditIcon,
    Trash2Icon,
    PlusSquareIcon,
    TopNav,
    SideNav,
    Footer
  },
  watch: {
    categoryId () {
      // id: number
      // console.log(`categoryId changed to: ${id}`)
      this.cachedCategory = JSON.parse(JSON.stringify(this.$store.getters.getCategoryById(this.categoryId)))
    }
  }
})
export default class CategoryEditor extends Vue {
  @Prop() private categoryId!: number;
  private fetching = false;
  private lastError = '';
  private cachedCategory: Category | null = null;
  private categoryColors: string[] = [
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
    'red',
    'orange',
    'yellow',
    'green',
    'teal',
    'cyan',
    'blue',
    'indigo',
    'purple',
    'pink'
  ];

  $refs!: {
    name: HTMLInputElement;
    color: HTMLSelectElement;
  }

  created () {
    this.cachedCategory = JSON.parse(JSON.stringify(this.$store.getters.getCategoryById(this.categoryId)))
    // console.log(this.cachedCategory)
  }

  get category () {
    return this.$store.getters.getCategoryById(this.categoryId)
  }

  get categories () {
    return this.$store.getters.getCategories
  }

  changeHandler () {
  // e: Event
  // if (e.srcElement === null) {
    // return
  // }
  // const srcElement = e.srcElement as HTMLElement
  // console.log(srcElement.id)
  }

  async handleSubmit () {
    this.fetching = true
    if (this.category.id === null) {
      let categoryId
      try {
        categoryId = await this.$store.dispatch('addCategory', this.cachedCategory)
      } catch (err) {
        this.lastError = err.toString()
        console.error(err)
        return
      } finally {
        this.fetching = false
      }
      this.$router.push('/categories')
      console.log(`Created category: ${categoryId}`)
      // this.$router.push(`/categoryEditor/${categoryId}`)
      return
    }

    try {
      await this.$store.dispatch('editCategory', this.cachedCategory)
    } catch (err) {
      this.lastError = err.toString()
      console.error(err)
      return
    } finally {
      this.fetching = false
    }
    this.$router.push('/categories')
  }
}
