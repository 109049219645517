





























































import { Component, Vue, Prop } from 'vue-property-decorator'
import { UsersIcon } from 'vue-feather-icons'
import TopNav from '@/components/TopNav.vue'
import SideNav from '@/components/SideNav.vue'
import Footer from '@/components/Footer.vue'
import ProfileTable from '@/components/ProfileTable.vue'
import CategoryBadge from '@/components/CategoryBadge.vue'

@Component({
  components: {
    TopNav,
    SideNav,
    Footer,
    ProfileTable,
    CategoryBadge,
    UsersIcon
  }
})
export default class Profiles extends Vue {
  @Prop() private categoryId!: number
  private initialPage: number | string = 1

  beforeMount () {
    if (this.$route.query.page && typeof this.$route.query.page === 'string') {
      this.initialPage = this.$route.query.page
    }
  }

  beforeUnmount () {
    console.log('beforeUnmount')
  }

  handleChangeProfileSelection ({ id, value }: { id: number; value: boolean }) {
    console.log('profile', id, value)
  }

  // TODO: Method is incomplete, pressing back after changing the page on the table doesn't trigger re-renderind
  async handlePageChange (page: number) {
    try {
      await this.$router.push({ query: Object.assign({}, this.$route.query, { page: page }) })
    } catch (e) {
      if (!e.message.includes('Navigation cancelled') && !e.message.includes('Avoided redundant navigation')) {
        console.error(e)
      }
    }
  }

  get profiles () {
    if (this.categoryId || this.categoryId === 0) {
      return this.$store.getters.getProfilesByCategoryId(this.categoryId)
    }
    return this.$store.getters.getProfiles
  }
}
