





















































































import { Component, Vue } from 'vue-property-decorator'
import {
  MenuIcon, BookIcon, UsersIcon,
  UserPlusIcon, ListIcon, PlusSquareIcon,
  SearchIcon, EyeIcon, VideoIcon
} from 'vue-feather-icons'
@Component({
  components: {
    MenuIcon,
    BookIcon,
    UsersIcon,
    UserPlusIcon,
    ListIcon,
    PlusSquareIcon,
    SearchIcon,
    EyeIcon,
    VideoIcon
  }
})
export default class SideNav extends Vue {
  get token () {
    return this.$store.getters.getToken
  }

  get apiAddress () {
    return this.$store.getters.getApiAddress
  }

  get currentRoutePath () {
    // console.log(this.$router.currentRoute)
    return this.$router.currentRoute.path
  }
}
