var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TopNav'),_c('div',{attrs:{"id":"layoutSidenav"}},[_c('SideNav'),_c('div',{attrs:{"id":"layoutSidenav_content"}},[_c('v-main',[_c('div',{staticClass:"page-header pb-10 page-header-dark bg-gradient-primary-to-secondary"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"page-header-content pt-5 pb-3"},[_c('h1',{staticClass:"page-header-title"},[_c('div',{staticClass:"page-header-icon"},[_c('ListIcon')],1),_c('span',{staticClass:"text-capitalize pl-2"},[_vm._v(" "+_vm._s(((_vm.$t('categories')) + ": " + (_vm.$t('list'))))+" ")])])])])]),_c('div',{staticClass:"container-fluid mt-n10"},[_c('div',{staticClass:"card mb-4"},[_c('div',{staticClass:"card-body"},[_c('BootstrapAlert',{attrs:{"lastError":_vm.lastError},on:{"click":function($event){_vm.lastError = null}}}),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.categories,"loading":_vm.categories.length === 0,"items-per-page":_vm.$store.getters.getConfig.categories.resultsPerPage,"search":_vm.search,"dense":"","footer-props":{
                  showFirstLastPage: true,
                  itemsPerPageOptions: _vm.$store.getters.getConfig.general.tableResultsPerPageOptions,
                }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:" align-items-end"},[_c('v-col',{staticStyle:{"padding":"0px"},attrs:{"md":"6","offset-md":"3"}},[_c('v-text-field',{staticClass:"mx-4 pt-0 mt-0",attrs:{"label":_vm.$t('Search'),"clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-divider',{staticClass:"mb-0 mt-0"})]},proxy:true},{key:"item.name",fn:function(ref){
                var item = ref.item;
return [_c('CategoryBadge',{attrs:{"categoryId":item.id}})]}},{key:"item.color",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.color.includes('#') ? item.color : _vm.$t(item.color))+" ")]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{staticClass:"btn btn-transparent-dark btnFix mr-2",attrs:{"to":("/categoryEditor/" + (item.id))}},[_c('EditIcon')],1),_c('button',{staticClass:"btn btn-warning btnFix",attrs:{"disabled":_vm.deletingCategory},on:{"click":function($event){return _vm.removeCategory(item.id)}}},[(_vm.deletingCategory === item.id)?_c('img',{staticStyle:{"width":"1rem","height":"1rem"},attrs:{"src":require("@/assets/img/rings.svg")}}):_c('Trash2Icon')],1)]}}])})],1)])])]),_c('Footer')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }