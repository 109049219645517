















































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import Detection from '@/types/Detection'
import CategoryBadge from '@/components/CategoryBadge.vue'
import Category from '@/types/Category'
import { DetectionTableConnectionOptions } from '@/types'
import ButtonToggler from '@/components/ButtonToggler.vue'
import { format } from 'date-fns'

@Component({
  components: {
    CategoryBadge,
    ButtonToggler
  }
})
export default class DetectionTable extends Vue {
  @Prop({ default: [], required: true }) private headers!: object[];
  @Prop({ default: [], required: true }) private detections!: Detection[];
  @Prop({ default: false, required: false }) private loading!: boolean;
  @Prop({ default: undefined, required: false }) private loadingText!:
    | string
    | undefined;

  @Prop({ default: undefined, required: false }) private noDataText!:
    | string
    | undefined;

  @Prop({ default: undefined, required: false }) private noResultsText!:
    | string
    | undefined;

  @Prop({ default: true, required: false }) private showSearch!: boolean;
  @Prop({ default: false, required: false }) private highlightLatest!: boolean;
  @Prop({ default: 10, required: false }) private itemsPerPage!: number;
  @Prop({ default: undefined, required: false }) private connectionOptions!: DetectionTableConnectionOptions | undefined

  private search = '';
  private dialogDetection: Detection | null = null;
  private dialog = false;
  private searchError = false;
  private formattedTime = '';

  get apiBaseURI () {
    return this.$store.getters.getApiBaseURI
  }

  beforeMount () {
    this.updateTime()
    setInterval(this.updateTime, 1000)
  }

  //   text-muted
  updateTime () {
    this.formattedTime = format(new Date(), 'yyyy-MM-dd kk:mm:ss')
  }

  latestDetectionCheck (detection: Detection) {
    let returnClasses = 'fixedTable'
    if (this.highlightLatest && this.detections.length) {
      const shouldHighlight =
        Date.now() - new Date(detection.datetime_event).getTime() < 60 * 1000
      returnClasses += shouldHighlight ? ' latestDetectionRow' : ''
    }
    return returnClasses
  }

  // Do some special filtering to account for categories not being a string
  customFilter (value: [Category], search: string | null) {
    if (!search) {
      return true
    }
    const typeOfValue = typeof value
    if (typeOfValue === 'undefined') {
      return
    }
    let filterableString = ''
    if (typeOfValue !== 'string' && typeOfValue !== 'number') {
      for (const category of value) {
        filterableString += category.name + ' '
      }
    } else {
      filterableString += value
    }
    try {
      const filterRegex = new RegExp(`${search}`, 'mi')
      const regexResult = filterRegex.test(filterableString)
      this.searchError = false
      return regexResult
    } catch (err) {
      this.searchError = true
      return false
    }
  }

  getDatetimeFromTimestamp (detection: Detection) {
    // console.log(detection)
    const date = new Date(detection.timestamp_event)
    const [month, day, year] = [`0${date.getMonth() + 1}`.slice(-2), `0${date.getDate()}`.slice(-2), `${date.getFullYear()}`]
    const [hour, minutes, seconds] = [`0${date.getHours()}`.slice(-2), `0${date.getMinutes()}`.slice(-2), `0${date.getSeconds()}`.slice(-2)]
    return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`
  }
}
