import './mixins/RegisterClassComponentHooks'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import i18n from './i18n'

import './styles.css'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = true

router.beforeEach(async (to, from, next) => {
  const authenticationStatus = await store.dispatch('verifyAuthenticationStatus')
  const routesAllowedNotAuth = [
    '/live-events',
    '/login',
    '/'
  ]
  const routesToLogin = [
    '/login',
    '/'
  ]
  // console.log('************ AUTH ROUTE:', to.path, authenticationStatus)
  if (routesAllowedNotAuth.includes(to.path)) {
    next()
    return
  } else if (!routesAllowedNotAuth.includes(to.path) && !authenticationStatus) {
    next({ name: 'Home' })
    return
  } else if (routesToLogin.includes(to.path) && authenticationStatus) {
    next({ name: 'Live' })
    return
  }
  next()
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
