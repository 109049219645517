











import { Color } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
  components: {
  }
})
export default class CategoryBadge extends Vue {
  @Prop({ default: 0, required: true }) private categoryId!: number;
  @Prop({ default: false, required: false }) private spawnClose!: boolean;
  private customColor: string | null = null
  private customTextColor: string | null = null

  beforeMount () {
    if (this.getCategoryById?.color?.includes('#')) {
      const parsedColor = this.parseHexColor(this.getCategoryById.color)
      if (parsedColor) {
        this.customColor = this.getCategoryById.color
        // Take the color and compare its contrast to white and black,
        // use this number to select a background color
        const white = new Color(255, 255, 255)
        const black = new Color(0, 0, 0)

        const contrastToWhite = this.calculateContrast(parsedColor, white)
        const contrastToBlack = this.calculateContrast(parsedColor, black)
        if (contrastToBlack > contrastToWhite) {
          this.customTextColor = black.toHexString()
        } else {
          this.customTextColor = white.toHexString()
        }
        // console.log(`CONTRAST FOR COLOR ${this.customColor}: CTW: ${contrastToWhite} CTB: ${contrastToBlack}`)
      }
    }
  }

  calculateLuminance ({ r, g, b }: Color): number {
    const a = [r, g, b].map(function (v) {
      const normV = v / 255
      return normV <= 0.03928 ? normV / 12.92 : Math.pow((normV + 0.055) / 1.055, 2.4)
    })
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
  }

  calculateContrast (firstColor: Color, secondColor: Color): number {
    const firstColorLuminance = this.calculateLuminance(firstColor)
    const secondColorLuminance = this.calculateLuminance(secondColor)
    const brightest = Math.max(firstColorLuminance, secondColorLuminance)
    const darkest = Math.min(firstColorLuminance, secondColorLuminance)
    return (brightest + 0.05) / (darkest + 0.05)
  }

  parseHexColor (hexColor: string): Color | null {
    const parserRegex = /#([0-9a-f]{1,2})([0-9a-f]{1,2})([0-9a-f]{1,2})/i
    const result = hexColor.match(parserRegex)
    if (result?.length === 4) {
      const color = new Color(parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16))
      return color
    }
    return null
  }

  get getCategoryById () {
    return this.$store.getters.getCategoryById(this.categoryId)
  }
}
