import Vue from 'vue'
import Vuex from 'vuex'
import VueI18n from 'vue-i18n'
import createPersistedState from 'vuex-persistedstate'
import { io } from 'socket.io-client'

import User from '@/types/User'
import Token from '@/types/Token'
import Profile from '@/types/Profile'
import Category from '@/types/Category'
import Image from '@/types/Image'
import FetchOptions from '@/types/FetchOptions'
import Detection from '@/types/Detection'
import Device from '@/types/Device'
import Config from '@/types/Config'
import {
  VideoUploadDetails,
  WebsocketState,
  WebsocketParameters,
  WebsocketSettings,
  WebsocketConnectionStatuses,
  WebsocketQuery
} from '@/types'

// Dummy data
// import { profiles } from '@/dummyData/profiles'
// import { categories } from '@/dummyData/categories'

const ephimeralProfile = {
  id: null,
  firstname: '',
  lastname: '',
  personalIdType: '',
  personalId: '',
  categories: []
}

const ephimeralCategory = {
  id: null,
  name: '',
  category: ''
}

Vue.use(Vuex)

// localStorage or sessionStorage, depending on the way we want the application to function
const STORAGE_INTERFACE = window.localStorage
// const API_PROTOCOL = 'http'
const API_PROTOCOL = window.location.protocol.replace(':', '')
// Use the env if available, otherwise default to detected host
// const API_ADDRESS = process.env.VUE_APP_IKFACE_DEFAULT_SERVER_HOST
//   ? process.env.VUE_APP_IKFACE_DEFAULT_SERVER_HOST : (
//     window.location.host === '.' ? '' : window.location.host
//   )
const API_ADDRESS = window.location.hostname + `${['', '80', '443'].includes(window.location.port) ? '' : `:${window.location.port}`}`
const AUTH_ROUTE = '/iklab/api/login'
const DEAUTH_ROUTE = '/iklab/api/logout'

const PROFILE_GET_ROUTE = '/iklab/ikface/api/profile'
const PROFILE_ADD_ROUTE = '/iklab/ikface/api/profile/add'
const PROFILE_EDIT_ROUTE = '/iklab/ikface/api/profile/edit'
const PROFILE_BUILD_FRP = '/iklab/ikface/api/profile/buildfrp'
const PROFILE_REMOVE_ROUTE = '/iklab/ikface/api/profile/remove'

const IMAGE_GET_ROUTE = '/iklab/ikface/api/image'
const IMAGE_ADD_ROUTE = '/iklab/ikface/api/image/add'
const IMAGE_REMOVE_ROUTE = '/iklab/ikface/api/image/remove'

const CATEGORY_GET_ROUTE = '/iklab/ikface/api/category'
const CATEGORY_ADD_ROUTE = '/iklab/ikface/api/category/add'
const CATEGORY_EDIT_ROUTE = '/iklab/ikface/api/category/edit'
const CATEGORY_REMOVE_ROUTE = '/iklab/ikface/api/category/remove'

const LANGUAGE_SET_ROUTE = '/iklab/api/language/set'
const LAST_MODIFICATION_ROUTE = '/iklab/ikface/api/last-modification'

const DETECTION_GET_ROUTE = '/iklab/ikface/api/query'

const VIDEO_CLASSIFICATION_ROUTE = '/iklab/ikface/api/actions/classification'

const WS_ROUTE = '/iklab/ikface/sio'
const WS_ACTIONS_ROUTE = '/iklab/ikface/actions-sio'

// FIXME: This route does not work atm
const CONFIG_GET_ROUTE = '/iklab/ikface/api/config'

const DEFAULT_FETCH_TIMEOUT = 30000

const DEFAULT_CONFIGURATION: Config = {
  general: {
    tableResultsPerPageOptions: [
      5,
      10,
      15,
      25,
      50,
      100
    ]
  },
  events: {
    live: {
      resultsPerPage: 10
    },
    search: {
      resultsPerPage: 10
    }
  },
  profiles: {
    resultsPerPage: 10,
    minimumPictureCount: 10,
    defaultProfilePicture: '/iklab/static/profiles/profile_default.jpg'
  },
  categories: {
    resultsPerPage: 10
  }
}

const TOKEN_LIFESPAN = 24 * 60 * 60 * 1000 // It was indicated that tokens will expire after 24 hours if unused

const websocketState = {} as WebsocketState

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: STORAGE_INTERFACE,
      paths: [
        'sideNavState',
        'activeLocale',
        'i18nInstance',
        'availableLocales',
        'apiProtocol',
        'apiAddress',
        'user',
        'token',
        'openToken',
        'profiles',
        'ephimeralProfile',
        'categories',
        'ephimeralCategory',
        'images',
        'ephimeralImages',
        // 'detections',
        // 'devices',
        // 'historicDetections',
        'ikfaceApiLastModification',
        'config',
        'activeVideoUploadDetails'
      ]
    })
  ],
  state: {
    sideNavState: true as boolean,
    activeLocale: process.env.VUE_APP_I18N_LOCALE || 'es' as string,
    i18nInstance: null as VueI18n | null,
    availableLocales: [] as string[],
    apiProtocol: API_PROTOCOL,
    apiAddress: API_ADDRESS,
    // Model instances
    user: null as User | null,
    token: null as Token | null,
    openToken: null as Token | null,
    profiles: [] as Profile[],
    ephimeralProfile: JSON.parse(JSON.stringify(ephimeralProfile)) as Profile,
    categories: [] as Category[],
    ephimeralCategory: JSON.parse(JSON.stringify(ephimeralCategory)) as Category,
    images: [] as Image[],
    ephimeralImages: [] as Image[],
    detections: [] as Detection[],
    devices: [] as Device[],
    historicDetections: [] as Detection[],
    ikfaceApiLastModification: 0 as number,
    config: DEFAULT_CONFIGURATION as Config,
    activeVideoUploadDetails: null as null | VideoUploadDetails,
    remainingStorageSpace: () => {
      return (1024 * 1024 * 5 - escape(encodeURIComponent(JSON.stringify(STORAGE_INTERFACE))).length)
    },
    websocketConnectionStates: {
      live: false,
      openLive: false,
      videoUploader: false
    } as WebsocketConnectionStatuses
  },
  mutations: {
    CLEAR_STORE (state) {
      state.user = null
      state.profiles = []
      state.ephimeralProfile = JSON.parse(JSON.stringify(ephimeralProfile))
      state.categories = []
      state.ephimeralCategory = JSON.parse(JSON.stringify(ephimeralCategory))
      state.images = []
      state.ephimeralImages = []
      state.detections = []
      state.devices = []
      state.historicDetections = []
      state.ikfaceApiLastModification = 0
      state.config = DEFAULT_CONFIGURATION as Config
    },
    SET_SIDE_NAV_STATE (state, data) {
      state.sideNavState = data
    },
    SET_ACTIVE_LOCALE (state, data) {
      state.activeLocale = data
      if (state.i18nInstance instanceof VueI18n) {
        state.i18nInstance.locale = data
      }
    },
    SET_AVAILABLE_LOCALES (state, data) {
      state.availableLocales = data
    },
    SET_API_PROTOCOL (state, protocol) {
      state.apiProtocol = protocol
    },
    SET_API_ADDRESS (state, address) {
      state.apiAddress = address
    },
    // User
    SET_USER (state, data) {
      state.user = data
    },
    // Token
    SET_TOKEN (state, data) {
      state.token = data
    },
    // OpenToken
    SET_OPEN_TOKEN (state, data) {
      state.openToken = data
    },
    CLEAR_TOKEN (state) {
      state.token = null
    },
    // Profile
    SET_PROFILES (state, profiles) {
      state.profiles = profiles
    },
    ADD_PROFILE (state, profile) {
      state.profiles.push(profile)
    },
    ADD_CATEGORY_TO_PROFILE (state, data) {
      let profile = state.profiles.find((profile) => {
        return profile.id === data.profileId
      })
      if (!profile) {
        profile = state.ephimeralProfile
      }
      if (!profile.categories.includes(data.categoryId)) {
        profile.categories.push(data.categoryId)
      }
    },
    REMOVE_CATEGORY_FROM_PROFILE (state, data) {
      let profile = state.profiles.find((profile) => {
        return profile.id === data.profileId
      })
      if (!profile) {
        profile = state.ephimeralProfile
      }
      profile.categories = profile.categories.filter((categoryId) => {
        return categoryId !== data.categoryId
      })
    },
    SET_EPHIMERAL_PROFILE (state, profile) {
      state.ephimeralProfile = JSON.parse(JSON.stringify(profile))
    },
    RESET_EPHIMERAL_PROFILE (state) {
      state.ephimeralProfile = JSON.parse(JSON.stringify(ephimeralProfile))
    },
    // Category
    SET_CATEGORIES (state, data) {
      state.categories = data
    },
    // Image
    SET_IMAGES (state, data) {
      state.images = data
    },
    ADD_IMAGE (state, data) {
      state.images.push(data)
    },
    REMOVE_IMAGE (state, id) {
      // console.log(id)
      state.images = state.images.filter((image) => {
        return image.id === id
      })
    },
    // Detection
    SET_DETECTIONS (state, detections) {
      state.detections = detections
    },
    CLEAR_DETECTIONS (state) {
      state.detections = []
    },
    ADD_DETECTION (state, detection) {
      state.detections.push(detection)
    },
    CLEAR_OLD_DETECTIONS (state) {
      state.detections = state.detections.filter((detection) => {
        return (Date.now() - (new Date(detection.datetime_event)).getTime()) < (24 * 60 * 60 * 1000)
      })
    },
    // Device
    SET_DEVICES (state, devices) {
      state.devices = devices
    },
    CLEAR_DEVICES (state) {
      state.devices = []
    },
    // Historic Detections
    SET_HISTORIC_DETECTIONS (state, historicDetections) {
      state.historicDetections = historicDetections
    },
    CLEAR_HISTORIC_DETECTIONS (state) {
      state.historicDetections = []
    },
    // Config
    SET_CONFIG (state, config) {
      state.config = config
    },
    // Synchronization
    SET_SYNC_TIME (state, data) {
      state.ikfaceApiLastModification = data
    },
    // Websocket
    SET_WEBSOCKET_CONNECTION_STATE (state, { route, connected }) {
      state.websocketConnectionStates[route] = connected
    },
    SET_ACTIVE_VIDEO_UPLOAD_DETAILS (state, details: VideoUploadDetails) {
      state.activeVideoUploadDetails = details
    },
    CLEAR_ACTIVE_VIDEO_UPLOAD_DETAILS (state) {
      state.activeVideoUploadDetails = null
    }
  },
  actions: {
    toggleSideNav (context) {
      context.commit('SET_SIDE_NAV_STATE', !context.state.sideNavState)
    },
    setActiveLocale (context, locale) {
      context.commit('SET_ACTIVE_LOCALE', locale)
    },
    setAvailableLocales (context, locales) {
      context.commit('SET_AVAILABLE_LOCALES', locales)
    },
    // Token
    async authenticateToAPI (context, credentials) {
      // url: http://[ip_server]/iklab/api/login
      // methods: POST
      // {
      //   "auth": base64(“[username or email]:[password]:[product_id]”)
      // }
      // headers:
      //   Content-Type:  application/json
      // ip_server: 192.168.10.169
      // username: admin
      // email: admin@iklab.cl
      // password: Admin2020
      // product_id: IKLAB004
      // console.log(credentials)
      const fetchBody = {
        auth: btoa(`${credentials.user}:${credentials.password}:IKLAB004`)
      }

      const fetchOptions: FetchOptions = {
        method: 'POST',
        timeout: DEFAULT_FETCH_TIMEOUT,
        body: fetchBody,
        route: AUTH_ROUTE
      }
      const authenticationResponse = await context.dispatch('fetchWithTimeout', fetchOptions)
      authenticationResponse.localTimestamp = Date.now()

      context.commit('CLEAR_STORE')
      context.commit('SET_TOKEN', authenticationResponse)
      await context.dispatch('synchronizeLocalModels')
      await context.dispatch('languageSet', context.getters.getActiveLocale)
    },
    // Token
    async authenticateToOpenSocket (context, credentials) {
      const fetchBody = {
        auth: btoa(`${credentials.user}:${credentials.password}:IKLAB004`)
      }

      const fetchOptions: FetchOptions = {
        method: 'POST',
        timeout: DEFAULT_FETCH_TIMEOUT,
        body: fetchBody,
        route: AUTH_ROUTE
      }
      const authenticationResponse = await context.dispatch('fetchWithTimeout', fetchOptions)
      context.commit('SET_OPEN_TOKEN', authenticationResponse)
      // await context.dispatch('languageSet', context.getters.getActiveLocale)
      return authenticationResponse
    },
    async logoutFromAPI (context) {
      const fetchBody = {
        atoken: context.getters.getToken.access_token
      }
      context.commit('CLEAR_STORE')
      context.commit('CLEAR_TOKEN')

      const fetchOptions: FetchOptions = {
        method: 'POST',
        timeout: DEFAULT_FETCH_TIMEOUT,
        body: fetchBody,
        route: DEAUTH_ROUTE
      }
      await context.dispatch('fetchWithTimeout', fetchOptions)
    },
    verifyAuthenticationStatus (context) {
      if (context.state.token === null) {
        return false
      }
      const tokenHasExpired = ((Date.now() - context.state.token.localTimestamp) > TOKEN_LIFESPAN)
      if (tokenHasExpired) {
        context.commit('CLEAR_STORE')
        context.commit('CLEAR_TOKEN')
        return false
      }
      return true
    },
    // Profile
    async loadProfiles (context) {
      const fetchOptions: FetchOptions = {
        method: 'GET',
        timeout: DEFAULT_FETCH_TIMEOUT,
        params: { atoken: context.getters.getToken.access_token, notDetailedCategories: '1' },
        route: PROFILE_GET_ROUTE
      }
      const profilesResponse = await context.dispatch('fetchWithTimeout', fetchOptions)
      // console.log(profilesResponse)
      context.commit('SET_PROFILES', profilesResponse.profiles)
    },
    async addProfile (context, params: Profile) {
      const newProfile = {
        atoken: context.getters.getToken.access_token,
        firstname: params.firstname,
        lastname: params.lastname,
        personalId: params.personalId,
        personalIdType: params.personalIdType,
        categories: params.categories
      }
      // console.log(newProfile)

      const fetchOptions: FetchOptions = {
        method: 'POST',
        timeout: DEFAULT_FETCH_TIMEOUT,
        body: newProfile,
        params: { atoken: context.getters.getToken.access_token },
        route: PROFILE_ADD_ROUTE
      }
      const profileCreationResponse = await context.dispatch('fetchWithTimeout', fetchOptions)
      // console.log(profileCreationResponse)
      await context.dispatch('loadProfiles')
      return profileCreationResponse.profileId
    },
    async editProfile (context, params: Profile) {
      const editedProfile = {
        atoken: context.getters.getToken.access_token,
        profileId: params.id,
        firstname: params.firstname,
        lastname: params.lastname,
        personalId: params.personalId,
        personalIdType: params.personalIdType,
        categories: params.categories
      }
      // console.log(editedProfile)

      const fetchOptions: FetchOptions = {
        method: 'POST',
        timeout: DEFAULT_FETCH_TIMEOUT,
        body: editedProfile,
        params: { atoken: context.getters.getToken.access_token },
        route: PROFILE_EDIT_ROUTE
      }
      await context.dispatch('fetchWithTimeout', fetchOptions)
      // const profileEditionResponse =
      // console.log('profileEdition')
      // console.log(profileEditionResponse)
      await context.dispatch('loadProfiles')
    },
    async toggleProfileStatus (context, params) {
      const editedProfile = {
        atoken: context.getters.getToken.access_token,
        profileId: params.profileId,
        status: params.status === 'E' ? 'D' : 'E'
      }

      const fetchOptions: FetchOptions = {
        method: 'POST',
        timeout: DEFAULT_FETCH_TIMEOUT,
        body: editedProfile,
        params: { atoken: context.getters.getToken.access_token },
        route: PROFILE_EDIT_ROUTE
      }
      await context.dispatch('fetchWithTimeout', fetchOptions)
      await context.dispatch('loadProfiles')
    },
    async removeProfile (context, params) {
      const fetchOptions: FetchOptions = {
        method: 'GET',
        timeout: DEFAULT_FETCH_TIMEOUT,
        params: {
          atoken: context.getters.getToken.access_token,
          profileId: params.profileId
        },
        route: PROFILE_REMOVE_ROUTE
      }
      await context.dispatch('fetchWithTimeout', fetchOptions)
      await context.dispatch('loadProfiles')
    },
    async addCategoryToProfileWithId (context, params) {
      context.commit('ADD_CATEGORY_TO_PROFILE', params)
    },
    async removeCategoryFromProfileWithId (context, params) {
      context.commit('REMOVE_CATEGORY_FROM_PROFILE', params)
    },
    async requestFaceRecognitionProfileGeneration (context, params) {
      const fetchOptions: FetchOptions = {
        method: 'GET',
        timeout: DEFAULT_FETCH_TIMEOUT,
        params: {
          atoken: context.getters.getToken.access_token,
          profileId: params.profileId
        },
        route: PROFILE_BUILD_FRP
      }
      await context.dispatch('fetchWithTimeout', fetchOptions)
    },
    // Image
    async loadImages (context) {
      const fetchOptions: FetchOptions = {
        method: 'GET',
        timeout: DEFAULT_FETCH_TIMEOUT,
        params: { atoken: context.getters.getToken.access_token },
        route: IMAGE_GET_ROUTE
      }
      const imagesResponse = await context.dispatch('fetchWithTimeout', fetchOptions)
      // console.log(imagesResponse)
      context.commit('SET_IMAGES', imagesResponse.imagesData)
    },
    async addImage (context, image) {
      // ADD
      // url: http://[ip_server]/iklab/ikface/api/image/add
      // methods: POST
      // parameters: JSON Format
      // headers: Content-Type:  application/json
      // * If imageAvatar is not present, by default its value is FALSE
      // {
      //   "atoken": [access_token],
      //   "profileId": [profile ID],
      //   "imageB64": [face image in string base64],
      //   "imageAvatar": [TRUE/FALSE] *
      // }
      // response: JSON Format
      // {
      //   "cod": 200,
      //   "mssg": "Image [imageName] created successfully",
      //   "imageId": [imageId last created],
      //   "imageName": "[imageName]",
      //   "imageUrl": "http://[ip_server]/iklab/static/profiles/[profileId]/[imageName]",
      //   "ikfaceApiLastModification": [Last modification Api models]
      // }
      const newImage = {
        atoken: context.getters.getToken.access_token,
        profileId: image.profileId,
        imageB64: image.url.replace(/data:\w+\/\w+;base64,/gi, ''),
        ext: image.mimeType.split('/')[1],
        imageAvatar: false
      }

      const fetchOptions: FetchOptions = {
        method: 'POST',
        timeout: DEFAULT_FETCH_TIMEOUT,
        body: newImage,
        params: { atoken: context.getters.getToken.access_token },
        route: IMAGE_ADD_ROUTE
      }
      await context.dispatch('fetchWithTimeout', fetchOptions)
      await context.dispatch('requestFaceRecognitionProfileGeneration', { profileId: image.profileId })
      await context.dispatch('loadImages')
    },
    async removeImage (context, params) {
      const fetchOptions: FetchOptions = {
        method: 'GET',
        timeout: DEFAULT_FETCH_TIMEOUT,
        params: {
          atoken: context.getters.getToken.access_token,
          imageId: params.imageId
        },
        route: IMAGE_REMOVE_ROUTE
      }

      const imagesLeft = context.state.images.filter((currentImage) => {
        return (currentImage.profileId === params.profileId)
      })

      await context.dispatch('fetchWithTimeout', fetchOptions)
      await context.dispatch('requestFaceRecognitionProfileGeneration', { profileId: params.profileId })
      // if (imagesLeft.length > 1) {
      // }
      await context.dispatch('loadImages')
    },
    async removeAllImagesForProfile (context, params) {
      const fetchOptions: FetchOptions = {
        method: 'GET',
        timeout: DEFAULT_FETCH_TIMEOUT,
        params: {
          atoken: context.getters.getToken.access_token,
          profileId: params.profileId
        },
        route: IMAGE_REMOVE_ROUTE
      }
      await context.dispatch('fetchWithTimeout', fetchOptions)
      await context.dispatch('requestFaceRecognitionProfileGeneration', { profileId: params.profileId })
      await context.dispatch('loadImages')
    },
    // Categories
    async loadCategories (context) {
      const fetchOptions: FetchOptions = {
        method: 'GET',
        timeout: DEFAULT_FETCH_TIMEOUT,
        params: { atoken: context.getters.getToken.access_token },
        route: CATEGORY_GET_ROUTE
      }
      const categoriesResponse = await context.dispatch('fetchWithTimeout', fetchOptions)
      // console.log(categoriesResponse)
      context.commit('SET_CATEGORIES', categoriesResponse.categories)
    },
    async addCategory (context, params: Category) {
      const newCategory = {
        atoken: context.getters.getToken.access_token,
        name: params.name,
        color: params.color
      }
      // console.log(newCategory)

      const fetchOptions: FetchOptions = {
        method: 'POST',
        timeout: DEFAULT_FETCH_TIMEOUT,
        body: newCategory,
        params: { atoken: context.getters.getToken.access_token },
        route: CATEGORY_ADD_ROUTE
      }
      const categoryCreationResponse = await context.dispatch('fetchWithTimeout', fetchOptions)
      // console.log(categoryCreationResponse)
      await context.dispatch('loadCategories')
      return categoryCreationResponse.categoryData.id
    },
    async editCategory (context, params: Category) {
      const editedCategory = {
        atoken: context.getters.getToken.access_token,
        categoryId: params.id,
        name: params.name,
        color: params.color
      }
      // console.log(editedCategory)

      const fetchOptions: FetchOptions = {
        method: 'POST',
        timeout: DEFAULT_FETCH_TIMEOUT,
        body: editedCategory,
        params: { atoken: context.getters.getToken.access_token },
        route: CATEGORY_EDIT_ROUTE
      }
      await context.dispatch('fetchWithTimeout', fetchOptions)
      // const categoryEditionResponse =
      // console.log('categoryEdition')
      // console.log(categoryEditionResponse)
      await context.dispatch('loadCategories')
    },
    async removeCategory (context, params) {
      const fetchOptions: FetchOptions = {
        method: 'GET',
        timeout: DEFAULT_FETCH_TIMEOUT,
        params: {
          atoken: context.getters.getToken.access_token,
          categoryId: params.categoryId
        },
        route: CATEGORY_REMOVE_ROUTE
      }
      await context.dispatch('fetchWithTimeout', fetchOptions)
      await context.dispatch('loadCategories')
    },
    // Historic Detections
    async loadHistoricDetections (context, { fromDate, toDate }) {
      /* eslint-disable @typescript-eslint/camelcase */
      const query = encodeURI(
        JSON.stringify({
          type_data: 'raw',
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          date_start: fromDate,
          date_end: toDate,
          client_id: context.getters.getClientId
        })
      )
      /* eslint-enable @typescript-eslint/camelcase */
      const fetchOptions: FetchOptions = {
        method: 'GET',
        timeout: DEFAULT_FETCH_TIMEOUT,
        params: {
          atoken: context.getters.getToken.access_token,
          query: query
        },
        route: DETECTION_GET_ROUTE
      }
      const detectionsResponse = await context.dispatch('fetchWithTimeout', fetchOptions)
      // console.log(detectionsResponse)
      for (const detection of detectionsResponse.data.detections) {
        try {
          detection.categories = typeof detection.categories === 'string' ? JSON.parse(detection.categories) : detection.categories
        } catch (e) {
          console.log(e)
        }
      }
      // console.log(detectionsResponse)
      context.commit('SET_HISTORIC_DETECTIONS', detectionsResponse.data.detections)
    },
    // Profile videos
    async uploadProfileVideo (context, { videos, prefix, filters }) {
      const newVideoData = new FormData()
      newVideoData.append('prefix', prefix)
      newVideoData.append('framerate', '15')
      newVideoData.append('backend', 'GST')
      newVideoData.append('filters', filters)
      newVideoData.append('videos', videos, videos.name)

      const fetchOptions: FetchOptions = {
        method: 'POST',
        timeout: DEFAULT_FETCH_TIMEOUT * 20,
        body: newVideoData,
        route: VIDEO_CLASSIFICATION_ROUTE,
        params: {
          atoken: context.getters.getToken.access_token
        }
      }

      const response = await context.dispatch('fetchWithTimeout', fetchOptions)
      // const response = { actionId: 'TESTTT' }
      await context.dispatch('loadProfiles')
      return response
    },
    // Config
    async loadConfiguration (context) {
      const fetchOptions: FetchOptions = {
        method: 'GET',
        timeout: DEFAULT_FETCH_TIMEOUT,
        params: { atoken: context.getters.getToken.access_token },
        route: CONFIG_GET_ROUTE
      }
      const configResponse = await context.dispatch('fetchWithTimeout', fetchOptions)
      context.commit('SET_CONFIG', configResponse)
    },
    // Language
    async languageSet (context, language) {
      // console.log(`Setting language to: ${language}`)
      // TODO: Changed this to a no-op due to upcoming backend changes
      return
      const authenticationStatus = await context.dispatch('verifyAuthenticationStatus')
      if (!authenticationStatus) {
        return
      }
      const fetchOptions: FetchOptions = {
        method: 'GET',
        timeout: DEFAULT_FETCH_TIMEOUT,
        params: { atoken: context.getters.getToken.access_token, langCod: language },
        route: LANGUAGE_SET_ROUTE
      }
      await context.dispatch('fetchWithTimeout', fetchOptions)
    },
    // Websocket
    async connectWebsocket (context, websocketParameters: WebsocketParameters) {
      // console.log('Connecting websocket', websocketParameters)
      // Get the manager up and running
      if (!websocketState[websocketParameters.route]) {
        let wsAddress: string
        const query: WebsocketQuery = {}
        // Parameters are route dependent, including URL
        switch (websocketParameters.route) {
          case 'openLive': {
            query.appKey = context.getters.getAppKey
            query.query = websocketParameters.query
            wsAddress = context.getters.getApiWsAddress
            break
          }
          case 'live': {
            query.atoken = context.getters.getToken.access_token
            query.query = websocketParameters.query
            wsAddress = context.getters.getApiWsAddress
            break
          }
          case 'videoUploader': {
            query.atoken = context.getters.getToken.access_token
            query.actionId = websocketParameters.actionId
            wsAddress = context.getters.getApiWsActionsAddress
            break
          }
          default:
            throw new Error(`Unknown socket route: ${websocketParameters.route}`)
        }

        const newWebsocket: WebsocketSettings = {
          route: websocketParameters.route,
          handlers: websocketParameters.handlers,
          socket: io(wsAddress, {
            autoConnect: false,
            query,
            // This is the weirdest fix, if we don't forceNew the welcome event arrives empty
            forceNew: true
          })
        }

        // Ensure reactivity
        Vue.set(websocketState, websocketParameters.route, newWebsocket)

        // console.log(` Websocket created ${websocketState[websocketParameters.route].socket.listenersAny().length}`, websocketState)
        // Register the global handlers
        // TODO: Handlers should be route dependent
        if (!websocketState[websocketParameters.route].socket.listenersAny().length) {
          websocketState[websocketParameters.route].socket.onAny((event: string, data) => {
            // console.log(`any: ${event} fired`, data)
            switch (event) {
              case 'welcome': {
                for (const detection of data.detections) {
                  try {
                    detection.categories = typeof detection.categories === 'string' ? JSON.parse(detection.categories) : detection.categories
                  } catch (e) {
                    console.error(e)
                  }
                }
                let counter = 1
                for (const detection of data.detections) {
                  detection.id = counter++
                }
                context.commit('SET_DETECTIONS', data.detections)
                context.commit('SET_DEVICES', data.devices)
                break
              }
              case 'detection': {
                let highestDetectionId = 0
                const detection = data
                for (const currentDetection of context.getters.getDetections) {
                  // console.log(`${currentDetection.id} > ${highestDetectionId}`)
                  if (currentDetection.id > highestDetectionId) {
                    highestDetectionId = currentDetection.id
                  }
                }
                detection.id = ++highestDetectionId
                detection.categories = typeof detection.categories === 'string' ? JSON.parse(detection.categories) : detection.categories
                context.commit('CLEAR_OLD_DETECTIONS', detection)
                context.commit('ADD_DETECTION', detection)
                break
              }
              case 'finished': {
                // noop for now
                break
              }
              case 'heartbeat': {
                // noop for now
                break
              }

              default: {
                console.error(`Unhandled detection websocket event found: ${event}`)
                break
              }
            }
          })
          websocketState[websocketParameters.route].socket.on('connect', () => {
            context.commit('SET_WEBSOCKET_CONNECTION_STATE', {
              route: websocketParameters.route,
              connected: true
            })
            // Vue.set(websocketState[websocketParameters.route], 'connected', true)
            // websocketState[websocketParameters.route].connected = true
          })
          websocketState[websocketParameters.route].socket.on('disconnect', () => {
            context.commit('SET_WEBSOCKET_CONNECTION_STATE', {
              route: websocketParameters.route,
              connected: false
            })
          })
        }

        // Register passed handlers
        const handlersAsArray = Object.entries(websocketParameters.handlers)
        for (let i = 0; i < handlersAsArray.length / 2; i++) {
          const [event, handler] = handlersAsArray[i]

          if (!websocketState[websocketParameters.route].socket.hasListeners(event)) {
            websocketState[websocketParameters.route].socket.on(event, handler)
          }
        }
        await websocketState[websocketParameters.route].socket.connect()
        return websocketState[websocketParameters.route].socket
      }
    },
    async disconnectWebsocket (context, route: string) {
      // console.log(`Disconnecting websocket: ${route}`)
      if (websocketState[route]) {
        // Clear affected models depending on route
        switch (route) {
          case 'live':
          case 'openLive': {
            context.commit('CLEAR_DETECTIONS')
            context.commit('CLEAR_DEVICES')
            break
          }
          case 'videoUploader': {
            // noop
            break
          }
          default:
            console.error(`Unrecognized websocket route: ${route}`)
            break
        }

        // Disconnect the socket
        await websocketState[route].socket.disconnect()

        // Destroy the instance
        delete websocketState[route]
        // console.log(`Websocket connection closed for "${route}"`)
      } else {
        console.warn(`Websocket instance for "${route}" does not exist`)
      }
    },
    // Synchronization
    async checkSynchronization (context) {
      // context.getters.getIkfaceApiLastModification
      const fetchOptions: FetchOptions = {
        method: 'GET',
        timeout: DEFAULT_FETCH_TIMEOUT,
        params: { atoken: context.getters.getToken.access_token },
        route: LAST_MODIFICATION_ROUTE
      }
      const lastModificationResponse = await context.dispatch('fetchWithTimeout', fetchOptions)
      // console.log(lastModificationResponse)
      return lastModificationResponse.ikfaceApiLastModification
      // context.commit('SET_LAST_MODIFICATION', lastModificationResponse)
    },
    async synchronizeLocalModels (context) {
      // console.log('synchronizeLocalModels', context)

      const syncCheck = await context.dispatch('checkSynchronization')
      const localSyncCheck = context.getters.getIkfaceApiLastModification

      // console.log(`Sync? = ${syncCheck}(${typeof syncCheck}) !== ${localSyncCheck}(${typeof localSyncCheck})`)
      if (syncCheck !== localSyncCheck) {
        console.log(`Requesting model sync: ${(new Date().toISOString())}`)
        await Promise.all([
          // TODO: Enable this when it's ready
          // context.dispatch('loadConfiguration'),
          context.dispatch('loadProfiles'),
          context.dispatch('loadCategories'),
          context.dispatch('loadImages')
        ])
      }
      context.commit('SET_SYNC_TIME', syncCheck)
    },
    async fetchWithTimeout (context, options: FetchOptions) {
      const controller = new AbortController()
      if (options.signal) {
        options.signal.addEventListener('abort', () => controller.abort())
      }

      const url = new URL(`${context.getters.getApiProtocol}://${context.getters.getApiAddress}${options.route}`)
      for (const param in options.params) {
        if (Object.prototype.hasOwnProperty.call(options.params, param)) {
          // console.log(param, typeof param)
          // console.log(options.params[param], typeof options.params[param])
          url.searchParams.append(param, options.params[param])
        }
      }
      // console.log(options.params)
      // console.log(`Requesting from: ${url.toString()}`)

      const fetchInit = {
        method: options.method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors' as const, // cors, no-cors, *cors, same-origin
        cache: 'no-cache' as const, // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin' as const, // include, *same-origin, omit
        redirect: 'follow' as const, // manual, *follow, error
        referrerPolicy: 'no-referrer' as const, // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        headers: {},
        signal: controller.signal,
        body: options.body instanceof FormData ? options.body : JSON.stringify(options.body)
      }

      // This is so ugly...
      if (!(options.body instanceof FormData)) {
        fetchInit.headers = {
          'Content-Type': 'application/json'
        }
      }
      const timeout = setTimeout(() => {
        controller.abort()
      }, options.timeout)
      let fetchResult
      let parsedResponse
      try {
        fetchResult = await fetch(url.toString(), fetchInit)
        parsedResponse = await fetchResult.json()
      } catch (err) {
        if (err.name === 'AbortError') {
          throw new Error('Request timed out or the user aborted the request')
        }
        throw err
      } finally {
        clearTimeout(timeout)
      }

      if (fetchResult.status > 299) {
        if (parsedResponse?.api) {
          throw new Error(parsedResponse?.message)
        }
        throw new Error(`Received error code on protocol status: ${fetchResult.status}`)
      }
      if (parsedResponse.cod > 299) {
        throw new Error(`Received error code from API -> ${parsedResponse.cod}: ${parsedResponse.mssg}`)
      }
      return parsedResponse
    }
  },
  getters: {
    getAppVersion: () => process.env.VUE_APP_IKFACE_VERSION,
    getAppKey: () => process.env.VUE_APP_IKFACE_APPKEY,
    getLastModificationCheckInterval: () => process.env.VUE_APP_IKFACE_LAST_MODIFICATION_CHECK_INTERVAL,
    getSideNavState: state => state.sideNavState,
    getActiveLocale: state => state.activeLocale,
    getAvailableLocales: state => state.availableLocales,
    getApiProtocol: state => state.apiProtocol,
    getApiAddress: state => state.apiAddress,
    getApiWsAddress: (state) => {
      return `${state.apiProtocol}://${state.apiAddress}` + WS_ROUTE
    },
    getApiWsActionsAddress: (state) => {
      return `${state.apiProtocol}://${state.apiAddress}` + WS_ACTIONS_ROUTE
    },
    getApiBaseURI: (state) => {
      return `${state.apiProtocol}://${state.apiAddress}/`
    },
    getProfiles: state => state.profiles,
    getProfileById: (state) => (id: number) => {
      if (id || id === 0) {
        return state.profiles.find((profile) => {
          return profile.id === id
        })
      }
      return state.ephimeralProfile
    },
    getProfilesByCategoryId: (state) => (id: number) => {
      return state.profiles.filter((profile) => {
        return profile.categories.includes(id)
      })
    },
    getEphimeralProfile: state => state.ephimeralProfile,
    getCategories: state => state.categories,
    getCategoryById: (state) => (id: number) => {
      if (id || id === 0) {
        return state.categories.find((category) => {
          return category.id === id
        })
      }
      return state.ephimeralCategory
    },
    getImages: state => state.images,
    getImagesByProfileId: (state) => (id: number) => {
      return state.images.filter((image) => {
        return image.profileId === id
      })
    },
    getAvatarUrlByProfileId: (state) => (id: number) => {
      const foundAvatars = state.images.filter((image) => {
        return (image.profileId === id) && (image.avatar === 1)
      })
      if (foundAvatars.length) {
        return `${state.apiProtocol}://${state.apiAddress}/${foundAvatars[0].imageUrl}`
      }
      const foundImages = state.images.filter((image) => {
        return (image.profileId === id)
      })
      if (foundImages.length) {
        return `${state.apiProtocol}://${state.apiAddress}/${foundImages[0].imageUrl}`
      }
      return `${state.apiProtocol}://${state.apiAddress}${state.config.profiles.defaultProfilePicture}`
    },
    getDetections: state => state.detections,
    getDevices: state => state.devices,
    getHistoricDetections: state => state.historicDetections,
    getToken: state => state.token,
    getClientId: state => state?.token?.client_id || 'ALL',
    getOpenToken: state => state.openToken,
    getIkfaceApiLastModification: state => state.ikfaceApiLastModification,
    getWebsocketConnectionStatus: (state) => (route: string) => {
      return state.websocketConnectionStates?.[route] || false
    },
    getActiveVideoUploadDetails: state => state.activeVideoUploadDetails,
    getConfig: (state) => state.config
  },
  modules: {
  }
})
