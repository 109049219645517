























































































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'
import { GlobeIcon, UserIcon } from 'vue-feather-icons'
import CategoryBadge from '@/components/CategoryBadge.vue'
import Category from '@/types/Category'

@Component({
  components: {
    CategoryBadge,
    GlobeIcon,
    UserIcon
  }
})
export default class Categorizer extends Vue {
  @Prop({ default: '', required: true }) private currentCategories!: number[];

  @Emit()
  addCategory (id: number) {
    return id
  }

  @Emit()
  removeCategory (id: number) {
    return id
  }

  get categories () {
    return this.$store.getters.getCategories()
  }

  get availableCategories () {
    return this.$store.getters.getCategories.filter((category: Category) => {
      return !this.currentCategories.includes(category.id)
    })
  }
}
