
















































import { Component, Vue } from 'vue-property-decorator'
import { EyeIcon } from 'vue-feather-icons'
// import { Route } from 'vue-router'
import { formatISO } from 'date-fns'
import TopNav from '@/components/TopNav.vue'
import SideNav from '@/components/SideNav.vue'
import Footer from '@/components/Footer.vue'
import CategoryBadge from '@/components/CategoryBadge.vue'
import DetectionInput from '@/types/DetectionInput'
import WSWelcomeMessage from '@/types/WSWelcomeMessage'
import DetectionTable from '@/components/DetectionTable.vue'
import { WebsocketParameters, DetectionTableConnectionOptions } from '@/types'

import { playNotification } from '@/components/Notification'

@Component({
  components: {
    TopNav,
    SideNav,
    Footer,
    CategoryBadge,
    EyeIcon,
    DetectionTable
  }
})
export default class Live extends Vue {
  private shouldHighlightLatest = false
  private highlightLatestTimeout: null | ReturnType<typeof setTimeout> = null
  private mutatingWebsocket = false

  async connectWebsocket () {
    // console.log('beforeMount')
    this.mutatingWebsocket = true
    try {
      const currentDate = formatISO(new Date(), { representation: 'date' })
      // console.log(`Connecting with date: ${currentDate}`) //  '2020-10-29' ||
      /* eslint-disable @typescript-eslint/camelcase */
      const query = encodeURI(
        JSON.stringify({
          type_data: 'raw',
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          date_start: currentDate,
          date_end: currentDate,
          client_id: this.$store.getters.getClientId
        })
      )

      /* eslint-enable @typescript-eslint/camelcase */
      const parameters: WebsocketParameters = {
        route: 'live',
        query,
        handlers: {
          welcome: this.welcomeHandler,
          detection: this.detectionHandler,
          heartbeat: (data: { mssg: string }) => {
            console.log(`heartbeat: ${data.mssg}`)
          }
        }
      }
      await this.$store.dispatch('connectWebsocket', parameters)
    } catch (error) {
      console.error('Problem connecting websocket', error)
    } finally {
      this.mutatingWebsocket = false
    }
  }

  async disconnectWebsocket () {
    this.mutatingWebsocket = true
    try {
      await this.$store.dispatch('disconnectWebsocket', 'live')
    } catch (error) {
      console.error('Problem disconnecting websocket', error)
    } finally {
      this.mutatingWebsocket = false
    }
  }

  async beforeMount () {
    await this.connectWebsocket()
  }

  async beforeDestroy () {
    await this.disconnectWebsocket()
  }

  async connectionToggler () {
    if (!this.getConnectionStatus) {
      await this.connectWebsocket()
    } else {
      await this.disconnectWebsocket()
    }
  }

  // async beforeRouteLeave (to: Route, from: Route, next: Function) {
  //   // console.log('beforeRouteLeave')
  //   next()
  // }

  welcomeHandler (data: WSWelcomeMessage) {
    console.log(`Welcome received with ${data.detections.length} detection/s`)
  }

  detectionHandler (detection: DetectionInput) {
    console.log(`Detection received ${detection.datetime_event}`)
    this.shouldHighlightLatest = true
    if (this.highlightLatestTimeout) {
      clearTimeout(this.highlightLatestTimeout)
    }
    this.highlightLatestTimeout = setTimeout(() => { this.shouldHighlightLatest = false }, 30 * 1000)
    playNotification()
  }

  get getDetections () {
    return this.$store.getters.getDetections
  }

  get getConnectionStatus () {
    return this.$store.getters.getWebsocketConnectionStatus('live')
  }

  get getDetectionTableConnectionOptions () {
    return {
      connected: this.getConnectionStatus,
      toggling: this.mutatingWebsocket,
      connectionToggler: this.connectionToggler
    } as DetectionTableConnectionOptions
  }

  get getTableHeaders () {
    return [
      {
        text: '#',
        align: 'start',
        sortable: true,
        value: 'id',
        divider: true
      },
      {
        text: this.$t('Name'),
        value: 'name',
        divider: true
      },
      {
        text: this.$t('Date'),
        value: 'datetime_event',
        divider: true
      },
      {
        text: this.$t('Categories'),
        value: 'categories',
        divider: true,
        sortable: false
      },
      {
        text: this.$t('Origin'),
        value: 'device_desc',
        divider: true,
        filterable: true
      },
      {
        text: this.$t('Profile Photo'),
        value: 'profilePhoto',
        sortable: false,
        filterable: false,
        divider: true
      },
      {
        text: this.$t('Detection Photo'),
        value: 'detectionPhoto',
        sortable: false,
        filterable: false,
        divider: true
      },
      {
        text: this.$t('Evidence'),
        value: 'evidence',
        sortable: false,
        filterable: false
      }
    ]
  }
}
