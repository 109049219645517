export interface ColorInterface {
  r: number;
  g: number;
  b: number;
  toHexString(): string;
}

export class Color implements ColorInterface {
  public r: number
  public g: number
  public b: number

  constructor (r: number, g: number, b: number) {
    this.r = r
    this.g = g
    this.b = b
  }

  toHexString () {
    return `#${this.r.toString(16).padStart(2, '0')}${this.g.toString(16).padStart(2, '0')}${this.b.toString(16).padStart(2, '0')}`
  }
}
