










































import { Component, Prop, Vue } from 'vue-property-decorator'
import { MenuIcon, LogOutIcon } from 'vue-feather-icons'
import LanguageSelector from '@/components/LanguageSelector.vue'
import store from '@/store'
@Component({
  components: {
    MenuIcon,
    LogOutIcon,
    LanguageSelector
  }
})
export default class TopNav extends Vue {
  @Prop() private msg!: string;

  toggleSideNav () {
    store.dispatch('toggleSideNav')
  }

  async requestLogout () {
    try {
      await this.$store.dispatch('logoutFromAPI')
    } catch (err) {
      console.error(err)
    }
    // this.$router.push('/login')
    this.$router.push('/')
  }
}
