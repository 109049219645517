























import { Component, Vue } from 'vue-property-decorator'
import { LogInIcon } from 'vue-feather-icons'
import { format } from 'date-fns'

@Component({
  components: {
    LogInIcon
  }
})
export default class ClearTopNav extends Vue {
  private formattedTime = '';
  private formattedYear = '';

  beforeMount () {
    this.updateTime()
    setInterval(this.updateTime, 1000)
  }

  //   text-muted
  updateTime () {
    this.formattedTime = format(new Date(), 'yyyy-MM-dd kk:mm:ss')
    this.formattedYear = format(new Date(), 'yyyy')
  }
}
