































































































































import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import { EditIcon, Trash2Icon, ToggleLeftIcon, ToggleRightIcon } from 'vue-feather-icons'
import Profile from '@/types/Profile'
import Category from '@/types/Category'
import CategoryBadge from '@/components/CategoryBadge.vue'
import ProfileStatusBadge from '@/components/ProfileStatusBadge.vue'
import BootstrapAlert from '@/components/BootstrapAlert.vue'

@Component({
  components: {
    EditIcon,
    Trash2Icon,
    CategoryBadge,
    BootstrapAlert,
    ToggleLeftIcon,
    ToggleRightIcon,
    ProfileStatusBadge
  }
})
export default class ProfileTable extends Vue {
  @Prop({ default: '', required: true }) private profiles!: Profile[]
  @Prop({ default: 1, required: false }) private pageHint!: number | string
  @Prop({ default: false, required: false }) private showSelection!: boolean
  private deletingProfile: number | null = null
  private togglingProfileStatus: number | null = null
  private lastError: string | null = null
  private search = ''
  private searchError = false
  private page = 1

  beforeMount () {
    this.page = this.parsePageHint(this.pageHint) || 1
  }

  async removeProfile (profileId: number) {
    const r = confirm(this.$t('confirmProfileDeletion').toString())
    if (r !== true) {
      return
    }
    this.deletingProfile = profileId
    try {
      // await this.$store.dispatch('removeAllImagesForProfile', { profileId })
      await this.$store.dispatch('removeProfile', { profileId })
    } catch (err) {
      this.lastError = err.toString()
      console.error(err)
      return
    } finally {
      this.deletingProfile = null
    }
    this.lastError = null
  }

  async toggleProfileStatus (profileId: number, profileStatus: string) {
    this.togglingProfileStatus = profileId
    try {
      await this.$store.dispatch('toggleProfileStatus', { profileId, status: profileStatus })
    } catch (err) {
      this.lastError = err.toString()
      console.error(err)
      return
    } finally {
      this.togglingProfileStatus = null
    }
    this.lastError = null
  }

  @Emit('changeProfileSelection')
  changeProfileSelection ({ item: profile, value }: { item: Profile; value: boolean }) {
    return { id: profile.id, value }
  }

  @Emit('changePage')
  handlePagination ({ page }: { page: number }) {
    return page
  }

  get tableHeaders () {
    return [
      {
        text: 'ID',
        align: 'start',
        sortable: true,
        value: 'id',
        divider: true
      },
      {
        text: this.$t('Firstname'),
        value: 'firstname',
        divider: true,
        filterable: true
      },
      {
        text: this.$t('Lastname'),
        value: 'lastname',
        divider: true,
        filterable: true
      },
      {
        text: this.$t('PersonalIdType'),
        value: 'personalIdType',
        divider: true
      },
      {
        text: this.$t('PersonalId'),
        value: 'personalId',
        divider: true,
        filterable: true
      },
      {
        text: this.$t('Categories'),
        value: 'categories',
        sortable: false,
        filterable: true,
        divider: true
      },
      {
        text: this.$t('Profile Photo'),
        value: 'profilePhoto',
        sortable: false,
        filterable: false,
        divider: true
      },
      {
        text: this.$t('imageCount'),
        value: 'imageCount',
        sortable: false,
        filterable: false,
        divider: true,
        align: 'center'
      },
      {
        text: this.$t('Status'),
        value: 'status',
        divider: true,
        filterable: false
      },
      {
        text: this.$t('Actions'),
        value: 'actions',
        sortable: false,
        filterable: false
      }
    ]
  }

  get apiBaseURI () {
    return this.$store.getters.getApiBaseURI
  }

  fixedClasses () {
    return 'fixedtable'
  }

  // Do some special filtering to account for categories not being a string
  customFilter (value: [number] | string | number, search: string | null) {
    if (!search) {
      return true
    }
    const typeOfValue = typeof value
    if (typeOfValue === 'undefined') {
      return
    }
    let filterableString = ''
    // Should be an array of category ids
    if (Array.isArray(value) && typeOfValue !== 'string' && typeOfValue !== 'number') {
      for (const category of value) {
        const targetCategory = this.$store.getters.getCategories.find((c: Category) => c.id === category)
        filterableString += targetCategory.name + ' '
      }
    } else {
      filterableString += value
    }
    try {
      const filterRegex = new RegExp(`${search}`, 'mi')
      const regexResult = filterRegex.test(filterableString)
      this.searchError = false
      return regexResult
    } catch (err) {
      this.searchError = true
      return false
    }
  }

  // pageHint can be a number or a string like "latest"
  parsePageHint (pageHint: number | string) {
    if (typeof pageHint === 'string') {
      const parsedPage = parseInt(pageHint)
      if (isNaN(parsedPage)) {
        switch (pageHint) {
          case 'latest': {
            const calculatedPage = Math.floor(this.profiles.length / this.$store.getters.getConfig.profiles.resultsPerPage)
            const lastPage = (this.profiles.length % this.$store.getters.getConfig.profiles.resultsPerPage) > 0 ? 1 : 0
            return calculatedPage + lastPage
          }
          default: {
            console.error(`Unknown page hint string: ${pageHint}`)
            return 1
          }
        }
      } else {
        return parsedPage
      }
    } else {
      return pageHint
    }
  }
}
