













































































































































import { Component, Vue } from 'vue-property-decorator'
import { SearchIcon } from 'vue-feather-icons'
import { subMonths, parse, formatISO, isAfter } from 'date-fns'
import TopNav from '@/components/TopNav.vue'
import SideNav from '@/components/SideNav.vue'
import Footer from '@/components/Footer.vue'
import CategoryBadge from '@/components/CategoryBadge.vue'
import DetectionTable from '@/components/DetectionTable.vue'
import BootstrapAlert from '@/components/BootstrapAlert.vue'

@Component({
  components: {
    TopNav,
    SideNav,
    Footer,
    CategoryBadge,
    SearchIcon,
    DetectionTable,
    BootstrapAlert
  }
})
export default class Search extends Vue {
  private fromDate = this.generateToday();
  private fromDateMenu = false;
  private toDate = this.generateToday();
  private toDateMenu = false;
  private loading = false;
  private lastError: string | null = null;

  async beforeMount () {
    this.requestDetections()
  }

  async requestDetections () {
    this.loading = true
    this.$store.commit('CLEAR_HISTORIC_DETECTIONS')
    try {
      await this.$store.dispatch('loadHistoricDetections', {
        fromDate: this.fromDate,
        toDate: this.toDate
      })
    } catch (err) {
      console.error(err)
      this.lastError = err.toString()
      return
    } finally {
      this.loading = false
    }
    this.lastError = null
  }

  generateToday () {
    return formatISO(new Date(), { representation: 'date' })
  }

  get getDetections () {
    return this.$store.getters.getHistoricDetections
  }

  get getTableHeaders () {
    return [
      {
        text: 'ID',
        align: 'start',
        sortable: true,
        value: 'id',
        divider: true
      },
      {
        text: this.$t('Name'),
        value: 'name',
        divider: true
      },
      {
        text: this.$t('Date'),
        value: 'datetime_event',
        divider: true
      },
      {
        text: this.$t('Categories'),
        value: 'categories',
        divider: true,
        sortable: false
      },
      {
        text: this.$t('Origin'),
        value: 'device_desc',
        divider: true,
        filterable: false
      },
      {
        text: this.$t('Profile Photo'),
        value: 'profilePhoto',
        sortable: false,
        filterable: false,
        divider: true
      },
      {
        text: this.$t('Detection Photo'),
        value: 'detectionPhoto',
        sortable: false,
        filterable: false,
        divider: true
      },
      {
        text: this.$t('Evidence'),
        value: 'evidence',
        sortable: false,
        filterable: false
      }
    ]
  }

  get fromDateMin () {
    // console.log(this.toDate)
    const parsedToDate = parse(this.toDate ? this.toDate : this.maxDate, 'yyyy-MM-dd', new Date())
    const newFromDateMin = subMonths(parsedToDate, 6)
    return formatISO(newFromDateMin, { representation: 'date' })
  }

  get fromDateMax () {
    return ''
  }

  fromDateSetToday () {
    const today = parse(this.generateToday(), 'yyyy-MM-dd', new Date())
    const comparison = isAfter(today, parse(this.toDate ? this.toDate : this.maxDate, 'yyyy-MM-dd', new Date()))
    if (comparison) {
      this.toDate = this.generateToday()
    }
    this.fromDate = this.generateToday()
  }

  get toDateMin () {
    return ''
  }

  get toDateMax () {
    return ''
  }

  toDateSetToday () {
    this.toDate = this.generateToday()
  }

  get maxDate () {
    // console.log('-')
    return formatISO(new Date(), { representation: 'date' })
  }
}
