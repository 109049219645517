










































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { EditIcon, Trash2Icon, UserPlusIcon } from 'vue-feather-icons'
import Profile from '@/types/Profile'
import TopNav from '@/components/TopNav.vue'
import SideNav from '@/components/SideNav.vue'
import Footer from '@/components/Footer.vue'
import Camera from '@/components/Camera.vue'
import Categorizer from '@/components/Categorizer.vue'
import ImageViewer from '@/components/ImageViewer.vue'
import BootstrapAlert from '@/components/BootstrapAlert.vue'

@Component<ProfileEditor>({
  components: {
    EditIcon,
    Trash2Icon,
    UserPlusIcon,
    TopNav,
    SideNav,
    Footer,
    Camera,
    Categorizer,
    ImageViewer,
    BootstrapAlert
  },
  watch: {
    profileId (id: number) {
      console.log(`profileId changed to: ${id}`)
      this.cachedProfile = JSON.parse(JSON.stringify(this.$store.getters.getProfileById(this.profileId)))
    }
  }
})
export default class ProfileEditor extends Vue {
  @Prop() private profileId!: number;
  private imageInput: 'fileUpload' | 'usbCamera' | 'networkCamera' = 'fileUpload';
  private uploadingImageFromCamera = false;
  private allowedImageFormats = ['image/png', 'image/jpg', 'image/jpeg'];

  private cachedProfile: Profile | null = null;
  private fetching = false;
  private imagesLoading = false;
  private lastError: string | null = null;

  $refs!: {
    personalIdType: HTMLSelectElement;
    personalId: HTMLInputElement;
    firstname: HTMLInputElement;
    lastname: HTMLInputElement;
    file: HTMLInputElement;
  }

  created () {
    this.cachedProfile = JSON.parse(JSON.stringify(this.$store.getters.getProfileById(this.profileId)))
  }

  get profile () {
    return this.$store.getters.getProfileById(this.profileId)
  }

  get categories () {
    return this.$store.getters.getCategories
  }

  get images () {
    return this.$store.getters.getImagesByProfileId(this.profile.id)
  }

  get idTypes () {
    return [
      {
        title: this.$t('N/A'),
        value: 'N/A'
      },
      {
        title: this.$t('PASSPORT'),
        value: 'PASSPORT'
      },
      {
        title: this.$t('DNI'),
        value: 'DNI'
      },
      {
        title: this.$t('RUT'),
        value: 'RUT'
      },
      {
        title: this.$t('SSN'),
        value: 'SSN'
      }
    ]
  }

  async imageSelectionHandler (e: Event) {
    if (e.target === null || this.$refs.file.files === null) {
      return
    }
    // console.log(e)
    // console.log(this.$refs.file.files)
    for (const image of this.$refs.file.files) {
      // console.log(image.type)
      if (!this.allowedImageFormats.includes(image.type)) {
        continue
      }
      const generatedDataUrl = await this.getDataURLFromFileReader(image) as string
      this.handleCameraCapture({ dataURL: generatedDataUrl, mimeType: image.type })
    }
  }

  async addCategoryToProfile (id: number) {
    // console.log(`Adding category with id: ${id}`)
    if (this.cachedProfile === null) {
      return
    }
    if (!this.cachedProfile.categories.includes(id)) {
      this.cachedProfile.categories.push(id)
    }

    // try {
    //   await this.$store.dispatch('editProfile', this.cachedProfile)
    // } catch (err) {
    //   this.lastError = err.toString()
    //   console.error(err)
    //   return
    // } finally {
    //   this.fetching = false
    // }
  }

  async removeCategoryFromProfile (id: number) {
    // console.log(`Removing category with id: ${id}`)
    if (this.cachedProfile === null) {
      return
    }
    this.cachedProfile.categories = this.cachedProfile.categories.filter((category: number) => {
      return !(category === id)
    })
    // const categoryIndex = this.categories.indexOf(id)
    // if (categoryIndex > -1) {
    //   this.categories.splice(categoryIndex, 1)
    // }
    // this.$store.dispatch('removeCategoryFromProfileWithId', {
    //   profileId: this.profile.id,
    //   categoryId: id
    // })
    // try {
    //   await this.$store.dispatch('editProfile', this.cachedProfile)
    // } catch (err) {
    //   this.lastError = err.toString()
    //   console.error(err)
    //   return
    // } finally {
    //   this.fetching = false
    // }
  }

  async handleCameraCapture (imageDataURL: { dataURL: string; mimeType: string }) {
    // console.log(imageDataURL)
    this.uploadingImageFromCamera = true
    this.fetching = true
    try {
      await this.$store.dispatch('addImage', {
        profileId: this.profile.id,
        url: imageDataURL.dataURL,
        mimeType: imageDataURL.mimeType
      })
    } catch (err) {
      this.lastError = err.toString()
      console.error(err)
      return
    } finally {
      this.fetching = false
      this.uploadingImageFromCamera = false
    }
  }

  async handleImageDeletion (id: number) {
    this.imagesLoading = true
    try {
      await this.$store.dispatch('removeImage', { imageId: id, profileId: this.profile.id })
    } catch (err) {
      this.lastError = err.toString()
      console.error(err)
      return
    } finally {
      this.imagesLoading = false
    }
  }

  async handleSubmit () {
    this.fetching = true
    if (this.profile.id === null) {
      let profileId
      try {
        profileId = await this.$store.dispatch('addProfile', this.cachedProfile)
      } catch (err) {
        this.lastError = err.toString()
        console.error(err)
        return
      } finally {
        this.fetching = false
      }
      this.$router.push(`/profileEditor/${profileId}`)
      return
    }

    try {
      await this.$store.dispatch('editProfile', this.cachedProfile)
    } catch (err) {
      this.lastError = err.toString()
      console.error(err)
      return
    } finally {
      this.fetching = false
    }
  }

  loadURLToHTMLImageElement (url: string, elem: HTMLImageElement) {
    return new Promise((resolve, reject) => {
      elem.onload = () => resolve(elem)
      elem.onerror = reject
      elem.src = url
    })
  }

  getDataURLFromFileReader (file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (e: ProgressEvent) => {
        const target = e.target as FileReader
        const result = (target && target.result) as string
        // console.log(typeof result)
        resolve(result)
      }
      reader.onerror = (e) => {
        reject(e)
      }
      reader.readAsDataURL(file)
    })
  }
}
