










































































































































import { Component, Emit, Vue, Prop } from 'vue-property-decorator'
// import { v4 as uuidv4 } from 'uuid'
import { UploadIcon, SettingsIcon } from 'vue-feather-icons'
import ImageViewer from '@/components/ImageViewer.vue'
import { CustomMediaStreamConstraints } from '@/types'

@Component({
  components: {
    ImageViewer,
    UploadIcon,
    SettingsIcon
  }
})
export default class Camera extends Vue {
  @Prop({ required: true }) private uploading!: boolean;
  private stream: null | MediaStream = null;
  private streaming = false;
  private flipped = false;
  private noCameraAccess = false;
  private noCameraAccessError = '';
  private captureTriggered = false;
  private videoTag: HTMLVideoElement | null = null
  private availableDevices: MediaDeviceInfo[] = []
  private deviceCheckInterval: ReturnType<typeof setInterval> | null = null
  private activeDeviceId: string | null = null
  private showOptions = false
  $refs!: {
    canvasTag: HTMLCanvasElement;
    canvasVideoTag: HTMLCanvasElement;
  }

  async mounted () {
    // console.log('mounted')
    this.deInitialize()
    this.refreshDevices()
    this.initialize()
    if (this.deviceCheckInterval === null) {
      this.deviceCheckInterval = setInterval(this.refreshDevices, 1000)
    }
  }

  async refreshDevices () {
    // const supportedConstraints = await navigator.mediaDevices.getSupportedConstraints()
    const reduceIds = (r: string, device: MediaDeviceInfo) => r + device.deviceId + device.label
    const newDeviceEnumeration = await navigator.mediaDevices.enumerateDevices()
    // Only update if changes are detected
    if (this.availableDevices.reduce(reduceIds, '') !== newDeviceEnumeration.reduce(reduceIds, '')) {
      this.availableDevices = newDeviceEnumeration
    }
  }

  async initialize (deviceId: string | null = null) {
    this.noCameraAccess = false
    const mediaStreamConstraints: CustomMediaStreamConstraints = {
      video: {
        width: { min: 640, ideal: 1920 },
        height: { min: 480, ideal: 1080 }
      },
      audio: false
    }
    if (deviceId !== null) {
      mediaStreamConstraints.video.deviceId = { exact: deviceId }
    }
    try {
      this.stream = await navigator.mediaDevices.getUserMedia(mediaStreamConstraints)
      this.activeDeviceId = this.stream.getVideoTracks()?.[0]?.getSettings()?.deviceId || null
    } catch (err) {
      console.error(err)
      if (err.name === 'TypeError' || err.name === 'NotAllowedError') {
        await new Promise((resolve) => setTimeout(resolve, 300))
        this.noCameraAccess = true
        this.noCameraAccessError = err.name
      }
      return
    }
    this.videoTag = document.createElement('video')
    this.videoTag.srcObject = this.stream
    await this.videoTag.play()
    const roi = this.calculateRegionOfInterest(this.videoTag.videoWidth, this.videoTag.videoHeight)
    this.$refs.canvasTag.width = roi.w
    this.$refs.canvasTag.height = roi.h
    this.streaming = true
    window.requestAnimationFrame(this.updateCanvas)
    // console.log(`${this.videoTag.width}x${this.videoTag.height} => ${this.videoTag.videoWidth}x${this.videoTag.videoHeight}`)
  }

  async updateCanvas () {
    if (!this.streaming) {
      return
    }
    const context = this.$refs.canvasVideoTag.getContext('2d')
    if (context === null || this.videoTag === null) {
      return
    }
    const roi = this.calculateRegionOfInterest(this.videoTag.videoWidth, this.videoTag.videoHeight)
    this.$refs.canvasVideoTag.width = this.videoTag.videoWidth
    this.$refs.canvasVideoTag.height = this.videoTag.videoHeight
    context.clearRect(0, 0, this.videoTag.videoWidth, this.videoTag.videoHeight)
    if (this.flipped) {
      context.save()
      context.translate(this.videoTag.videoWidth, 0)
      context.scale(-1, 1)
    }
    context.drawImage(this.videoTag, 0, 0, this.videoTag.videoWidth, this.videoTag.videoHeight)
    if (this.flipped) {
      context.restore()
    }
    context.lineWidth = 5
    context.setLineDash([20, 20])
    context.strokeStyle = 'white'
    context.rect(roi.x, roi.y, roi.w, roi.h)
    context.stroke()
    window.requestAnimationFrame(this.updateCanvas)
  }

  capture () {
    const context = this.$refs.canvasTag.getContext('2d')
    if (context === null || this.videoTag === null) {
      return
    }

    const regionOfInterest = this.calculateRegionOfInterest(this.videoTag.videoWidth, this.videoTag.videoHeight)
    // console.log(regionOfInterest)
    this.$refs.canvasTag.width = regionOfInterest.w
    this.$refs.canvasTag.height = regionOfInterest.h
    if (this.flipped) {
      context.save()
      context.translate(regionOfInterest.w, 0)
      context.scale(-1, 1)
    }
    context.drawImage(
      this.videoTag,
      regionOfInterest.x,
      regionOfInterest.y,
      regionOfInterest.w,
      regionOfInterest.h,
      0,
      0,
      regionOfInterest.w,
      regionOfInterest.h
    )
    if (this.flipped) {
      context.restore()
    }
    this.captureTriggered = true
  }

  @Emit('capture')
  saveImage () {
    const canvasContext = this.$refs.canvasTag.getContext('2d')
    // let roi = null
    if (canvasContext === null) {
      console.error('Could not get 2d canvas context for the canvas')
      return
    }
    // if (this.videoTag) {
    //   roi = this.calculateRegionOfInterest(this.videoTag.videoWidth, this.videoTag.videoHeight)
    // }
    return {
      // uuid: uuidv4(),
      dataURL: this.$refs.canvasTag.toDataURL('image/png'),
      mimeType: 'image/png'
      // calculatedImageSize: JSON.stringify(roi)
    }
  }

  calculateRegionOfInterest (w: number, h: number) {
    const TARGET_WIDTH = 400
    const TARGET_HEIGHT = 600
    const REFERENCE_WIDTH = 1280
    const REFERENCE_HEIGHT = 720

    const widthCorrectionFactor = w / REFERENCE_WIDTH
    const heightCorrectionFactor = h / REFERENCE_HEIGHT

    const compensatedWidth = TARGET_WIDTH * widthCorrectionFactor
    const compensatedHeight = TARGET_HEIGHT * heightCorrectionFactor

    const roi = {
      x: Math.floor((w - compensatedWidth) / 2),
      y: Math.floor((h - compensatedHeight) / 2),
      w: compensatedWidth,
      h: compensatedHeight,
      cameraW: w,
      cameraH: h
    }
    // console.log('ROI', roi)
    return roi
  }

  deInitialize () {
    this.streaming = false
    if (this.videoTag !== null) {
      this.videoTag.srcObject = null
      this.videoTag.remove()
    }
    if (this.stream !== null) {
      for (const track of this.stream.getTracks()) {
        track.stop()
      }
    }
  }

  async selectDevice (e: InputEvent) {
    const selectElement = e.target as HTMLSelectElement
    await this.deInitialize()
    this.initialize(selectElement.value)
  }

  beforeDestroy () {
    if (this.deviceCheckInterval !== null) {
      clearInterval(this.deviceCheckInterval)
      this.deviceCheckInterval = null
    }
    this.deInitialize()
  }

  get getApiBaseURI () {
    return this.$store.getters.getApiBaseURI
  }

  get getValidAvailableDevices () {
    return this.availableDevices.filter((d) => {
      return d.kind === 'videoinput'
    })
  }
}
