





import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
  }
})
export default class App extends Vue {
  get sideNavState () {
    return this.$store.getters.getSideNavState
  }

  async created () {
    // console.log(`IKFace v${this.$store.getters.getAppVersion}, server: ${this.$store.getters.getApiBaseURI}, appKey: ${this.$store.getters.getAppKey.substr(0, 20)}... registered checkups (token/model validity) at: ${(new Date().toISOString())}`)
    const routesAllowedNotAuth = [
      '/live-events',
      '/login',
      '/'
    ]
    while (1) {
      const authenticationStatus = await this.$store.dispatch('verifyAuthenticationStatus')
      if (!routesAllowedNotAuth.includes(this.$router.currentRoute.path) && !authenticationStatus) {
        this.$router.push('/')
        continue
      }
      if (authenticationStatus) {
        try {
          await this.$store.dispatch('synchronizeLocalModels')
        } catch (err) {
          console.error(err)
          await this.delay(this.$store.getters.getLastModificationCheckInterval)
          continue
        }
      }
      await this.delay(this.$store.getters.getLastModificationCheckInterval)
    }
  }

  delay (ms = 1000) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(ms)
      }, ms)
    })
  }
}

