




















import { Component, Emit, Vue, Prop } from 'vue-property-decorator'
import { ToggleRightIcon, ToggleLeftIcon } from 'vue-feather-icons'

@Component({
  components: {
    ToggleRightIcon,
    ToggleLeftIcon
  }
})
export default class Camera extends Vue {
  @Prop({ required: true }) private isActive!: boolean;
  @Prop({ required: true }) private toggling!: boolean;

  @Emit('toggle')
  toggle () {
    // Emit the toggle event
  }
}
