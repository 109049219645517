












import { Component, Vue } from 'vue-property-decorator'
import store from '@/store'
@Component({
  components: {
  }
})
export default class LanguageSelector extends Vue {
  $refs!: {
    select: HTMLSelectElement;
  }

  async mounted () {
    try {
      await this.$store.dispatch('languageSet', this.$refs.select.value)
    } catch (err) {
      console.error(err)
    }
  }

  get localeList () {
    return store.getters.getAvailableLocales
  }

  async notifyBackend (e: Event) {
    const element = e.target as HTMLSelectElement
    this.$vuetify.lang.current = element.value
    try {
      await this.$store.dispatch('languageSet', element.value)
    } catch (err) {
      console.error(err)
    }
  }
}
