






















































































import { Component, Vue } from 'vue-property-decorator'
import { EditIcon, Trash2Icon, ListIcon } from 'vue-feather-icons'
import TopNav from '@/components/TopNav.vue'
import SideNav from '@/components/SideNav.vue'
import Footer from '@/components/Footer.vue'
import CategoryBadge from '@/components/CategoryBadge.vue'
import BootstrapAlert from '@/components/BootstrapAlert.vue'

@Component({
  components: {
    TopNav,
    SideNav,
    Footer,
    EditIcon,
    Trash2Icon,
    CategoryBadge,
    ListIcon,
    BootstrapAlert
  }
})
export default class Categories extends Vue {
  private deletingCategory: number | null = null;
  private lastError: string | null = null;
  private search = '';

  get categories () {
    return this.$store.getters.getCategories
  }

  get tableHeaders () {
    return [
      {
        text: 'ID',
        sortable: true,
        value: 'id',
        divider: true
      },
      {
        text: this.$t('Name'),
        value: 'name',
        divider: true
      },
      {
        text: this.$t('Color'),
        value: 'color',
        divider: true
      },
      {
        text: this.$t('Actions'),
        value: 'actions',
        sortable: false,
        filterable: false
      }
    ]
  }

  async removeCategory (categoryId: number) {
    const r = confirm(this.$t('confirmCategoryDeletion').toString())
    if (r !== true) {
      return
    }
    this.deletingCategory = categoryId
    try {
      await this.$store.dispatch('removeCategory', { categoryId })
    } catch (err) {
      this.lastError = err.toString()
      console.error(err)
      return
    } finally {
      this.deletingCategory = null
    }
  }
}
