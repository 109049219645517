


























































































































































































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { EditIcon, Trash2Icon, PlusSquareIcon, VideoIcon, InfoIcon } from 'vue-feather-icons'
import TopNav from '@/components/TopNav.vue'
import SideNav from '@/components/SideNav.vue'
import Footer from '@/components/Footer.vue'
import BootstrapAlert from '@/components/BootstrapAlert.vue'
import {
  WebsocketParameters,
  WSVideoUploaderFinished,
  VideoUploadAdvancedOptions,
  VideoUploadAdvancedOptionsDefaultGenerator,
  VideoUploadDetails
} from '@/types'
// import { Socket } from 'socket.io-client'

@Component<VideoUploader>({
  components: {
    EditIcon,
    Trash2Icon,
    PlusSquareIcon,
    InfoIcon,
    VideoIcon,
    TopNav,
    SideNav,
    Footer,
    BootstrapAlert
  }
})
export default class VideoUploader extends Vue {
  @Prop() private categoryId!: number
  private videoFile: null | File = null
  private allowedFormats = ['video/avi', 'video/mp4', 'video/3gp', 'video/3gpp', 'video/mov']
  // Video upload fetch status
  private fetching = false
  private uploaderText: string = this.$t('Choose file').toString()
  private lastError = ''
  private showVideoProgress = false
  private videoProcessStatus = ''
  private filename = ''
  private videoProcessTimeStarted = 0
  private videoProcessTimeElapsed = 0
  private videoProcessGeneratedProfiles: number | null = null
  private disableGoToListButton = true
  private videoProgressIndeterminate = true
  private advancedOptions: VideoUploadAdvancedOptions = VideoUploadAdvancedOptionsDefaultGenerator()

  $refs!: {
    prefix: HTMLInputElement;
    file: HTMLInputElement;
  }

  mounted () {
    setTimeout(this.updateVideoProcessingTime, 1000)
    // Check if there is an actionId active
    const uploadDetails: VideoUploadDetails = this.$store.getters.getActiveVideoUploadDetails
    if (uploadDetails !== null) {
      // Disable buttons
      this.fetching = true
      // Show progress modal
      this.showVideoProgress = true
      this.videoProcessTimeStarted = uploadDetails.videoProcessTimeStarted
      this.filename = uploadDetails.filename
      this.videoProcessStatus = `${this.$t('Uploading video')} [${this.filename}] ${this.$t('Please wait')}...`
      this.connectWebsocket(uploadDetails.actionId)
    }
  }

  videoSelectionHandler (e: Event) {
    if (e.target === null || this.$refs.file.files === null) {
      return
    }
    this.lastError = ''
    for (const file of this.$refs.file.files) {
      // console.log('file', file, !this.allowedFormats.includes(file.type))
      if (!this.allowedFormats.includes(file.type)) {
        this.uploaderText = this.$t('Choose file').toString()
        this.lastError = `${this.$t('File type not supported').toString()} ${file.type}. ${this.$t('Please use one of the following').toString()}: ${this.allowedFormats.join(', ')}.`
      } else {
        this.uploaderText = file.name
        this.videoFile = file
      }
    }
  }

  async handleSubmit () {
    // Disable buttons
    this.fetching = true
    // Show progress modal
    this.showVideoProgress = true
    this.videoProcessTimeStarted = Date.now()
    this.filename = this.videoFile?.name || ''
    this.videoProcessStatus = `${this.$t('Uploading video')} [${this.filename}] ${this.$t('Please wait')}...`
    try {
      // Upload video
      const { actionId } = await this.$store.dispatch('uploadProfileVideo', {
        videos: this.videoFile,
        prefix: this.$refs.prefix.value,
        filters: JSON.stringify(this.advancedOptions)
      })
      this.videoProcessStatus = `${this.$t('Video upload finished')} [${this.filename}]`
      const videoUploadDetails: VideoUploadDetails = {
        actionId,
        videoProcessTimeStarted: this.videoProcessTimeStarted,
        filename: this.filename
      }
      this.$store.commit('SET_ACTIVE_VIDEO_UPLOAD_DETAILS', videoUploadDetails)

      // Connect to websocket
      await this.connectWebsocket(actionId)
    } catch (err) {
      this.lastError = err.toString()
      this.fetching = false
      this.showVideoProgress = false
      this.videoProcessTimeStarted = 0
      this.videoProcessStatus = ''
    }
  }

  async connectWebsocket (actionId: string) {
    const parameters: WebsocketParameters = {
      route: 'videoUploader',
      actionId,
      handlers: {
        // TODO: NOT IMPLEMENTED ON BACKEND
        // progress: function (data: number) {
        //   console.log(`Progress ${data} `)
        // },
        finished: (data: WSVideoUploaderFinished) => {
          this.videoProcessStatus = `${this.$t('Video processed')} [${this.filename}]`
          this.videoProcessGeneratedProfiles = data.result_data.totalProfiles // data.totalProfiles
          this.disableGoToListButton = false
          this.videoProcessTimeStarted = 0
          this.filename = ''
          this.$store.commit('CLEAR_ACTIVE_VIDEO_UPLOAD_DETAILS')
          setTimeout(() => {
            this.fetching = false
            this.showVideoProgress = false
            // Redirect to profiles
            this.$router.push({ path: '/profiles', query: { page: 'latest' } })
          }, 10000)
        }
      }
    }
    // this.videoProcessStatus = 'NOW'

    // await (
    //   new Promise<void>((resolve, reject) => {
    //     setTimeout(() => { resolve() }, 10000)
    //   })
    // )
    // // Add a close handler to clean up in case ofa failed connection
    // const websocket: Socket = await this.$store.dispatch('connectWebsocket', parameters)
    // websocket.on('connection_error', (e) => {
    //   console.log('ERROR', e)
    // })
    await this.$store.dispatch('connectWebsocket', parameters)
    // Notify user of process success
    this.videoProcessStatus = `${this.$t('Processing video')} [${this.filename}] ${this.$t('Please wait')}...`
  }

  async disconnectWebsocket () {
    try {
      await this.$store.dispatch('disconnectWebsocket', 'videoUploader')
    } catch (error) {
      console.error('Problem disconnecting websocket', error)
    }
  }

  updateVideoProcessingTime () {
    if (this.videoProcessTimeStarted) {
      this.videoProcessTimeElapsed = Date.now() - this.videoProcessTimeStarted
    }
    setTimeout(this.updateVideoProcessingTime, 1000)
  }

  get getVideoProcessingTimes () {
    const HOUR_FACTOR = 1000 * 60 * 60
    const MINUTE_FACTOR = 1000 * 60
    const hours = Math.floor(this.videoProcessTimeElapsed / HOUR_FACTOR)
    const minutes = Math.floor((this.videoProcessTimeElapsed - (HOUR_FACTOR * hours)) / MINUTE_FACTOR)
    const seconds = Math.floor((this.videoProcessTimeElapsed - (HOUR_FACTOR * hours) - (MINUTE_FACTOR * minutes)) / 1000)
    const r = {
      hours: hours.toString().padStart(2, '0'),
      minutes: minutes.toString().padStart(2, '0'),
      seconds: seconds.toString().padStart(2, '0')
    }
    return r
  }

  async beforeDestroy () {
    await this.disconnectWebsocket()
  }
}
