




















import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
@Component({
  components: {
  }
})
export default class BootstrapAlert extends Vue {
  @Prop({ default: 0, required: true }) private lastError!: string;

  @Emit()
  click (e: Event) {
    return e
  }
}
