var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BootstrapAlert',{attrs:{"lastError":_vm.lastError},on:{"click":function($event){_vm.lastError = null}}}),_c('v-data-table',{attrs:{"show-select":_vm.showSelection,"page":_vm.page,"headers":_vm.tableHeaders,"items":_vm.profiles,"loading":_vm.profiles.length === 0,"items-per-page":_vm.$store.getters.getConfig.profiles.resultsPerPage,"search":_vm.search,"custom-filter":_vm.customFilter,"footer-props":{
      showFirstLastPage: true,
      itemsPerPageOptions: _vm.$store.getters.getConfig.general.tableResultsPerPageOptions,
    },"item-class":_vm.fixedClasses},on:{"item-selected":_vm.changeProfileSelection,"pagination":_vm.handlePagination},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:" align-items-end"},[_c('v-col',{staticStyle:{"padding":"0px"},attrs:{"md":"6","offset-md":"3"}},[_c('v-text-field',{staticClass:"mx-4 pt-0 mt-0",attrs:{"label":_vm.$t('Search'),"error":_vm.searchError,"clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-divider',{staticClass:"mb-0 mt-0"})]},proxy:true},{key:"header.data-table-select",fn:function(){return undefined},proxy:true},{key:"item.fullname",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(((item.firstname) + " " + (item.lastname)))+" ")]}},{key:"item.personalIdType",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(("" + (_vm.$t(item.personalIdType))))+" ")]}},{key:"item.categories",fn:function(ref){
    var item = ref.item;
return _vm._l((item.categories),function(category,cIndex){return _c('router-link',{key:("Profile" + (item.id) + "Badge" + cIndex),attrs:{"to":("/profiles/" + category)}},[_c('CategoryBadge',{attrs:{"categoryId":category}})],1)})}},{key:"item.imageCount",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","color":(_vm.$store.getters.getImagesByProfileId(item.id).length) >= _vm.$store.getters.getConfig.profiles.minimumPictureCount ? 'green': 'red',"text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$store.getters.getImagesByProfileId(item.id).length)+" ")])]}},{key:"item.profilePhoto",fn:function(ref){
    var item = ref.item;
return [(_vm.$store.getters.getAvatarUrlByProfileId(item.id))?_c('v-img',{staticClass:"my-2 mx-auto",attrs:{"src":_vm.$store.getters.getAvatarUrlByProfileId(item.id),"width":"90px","height":"90px"}}):_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(_vm.$t('No image')))])]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('ProfileStatusBadge',{staticClass:"mx-auto",attrs:{"status":item.status}})],1)]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{staticClass:"btn btn-transparent-dark btnFix mr-1",attrs:{"to":("/profileEditor/" + (item.id))}},[_c('EditIcon')],1),_c('button',{class:("btn btn-" + (item.status === 'E' ? 'success' : 'warning') + " btnFix mr-1"),attrs:{"disabled":_vm.togglingProfileStatus},on:{"click":function($event){return _vm.toggleProfileStatus(item.id, item.status)}}},[(_vm.togglingProfileStatus === item.id)?_c('img',{staticStyle:{"width":"1rem","height":"1rem"},attrs:{"src":require("@/assets/img/rings.svg")}}):(item.status === 'E')?_c('ToggleRightIcon'):_c('ToggleLeftIcon')],1),_c('button',{staticClass:"btn btn-danger btnFix",attrs:{"disabled":_vm.deletingProfile},on:{"click":function($event){return _vm.removeProfile(item.id)}}},[(_vm.deletingProfile === item.id)?_c('img',{staticStyle:{"width":"1rem","height":"1rem"},attrs:{"src":require("@/assets/img/rings.svg")}}):_c('Trash2Icon')],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }