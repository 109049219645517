import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Profiles from '@/views/Profiles.vue'
import ProfileEditor from '@/views/ProfileEditor.vue'
import Login from '@/views/Login.vue'
import Categories from '@/views/Categories.vue'
import CategoryEditor from '@/views/CategoryEditor.vue'
import Live from '@/views/Live.vue'
import Search from '@/views/Search.vue'
import PageError from '@/views/PageError.vue'
import OpenLive from '@/views/OpenLive.vue'
import VideoUploader from '@/views/VideoUploader.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/profiles/:categoryId?',
    name: 'Profiles',
    component: Profiles,
    props: (route) => {
      // Cast the parameter to a number
      const categoryId = Number.parseInt(route.params.categoryId, 10)
      // console.log(`${route.params.categoryId} ====> ${categoryId} ====> ${Number.isNaN(categoryId)}`)
      if (Number.isNaN(categoryId)) {
        return { categoryId: null }
      }
      return {
        categoryId
      }
    }
  },
  {
    path: '/profileEditor/:profileId?',
    name: 'ProfileEditor',
    component: ProfileEditor,
    props: (route) => {
      // Cast the parameter to a number
      const profileId = Number.parseInt(route.params.profileId, 10)
      if (Number.isNaN(profileId)) {
        return { profileId: null }
      }
      return {
        profileId
      }
    }
  },
  {
    path: '/videoUploader',
    name: 'VideoUploader',
    component: VideoUploader
  },
  // {
  //   path: '/',
  //   name: 'OpenLive',
  //   component: OpenLive
  // },
  {
    path: '/live-events',
    name: 'LiveEvents',
    component: OpenLive
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Home',
    component: Login
  },
  {
    path: '/categories',
    name: 'Categories',
    component: Categories
  },
  {
    path: '/categoryEditor/:categoryId?',
    name: 'CategoryEditor',
    component: CategoryEditor,
    props: (route) => {
      // Cast the parameter to a number
      const categoryId = Number.parseInt(route.params.categoryId, 10)
      if (Number.isNaN(categoryId)) {
        return { categoryId: null }
      }
      return {
        categoryId
      }
    }
  },
  {
    path: '/live',
    name: 'Live',
    component: Live
  },
  // {
  //   path: '/openLive',
  //   name: 'OpenLive',
  //   component: OpenLive
  // },
  {
    path: '/search',
    name: 'Search',
    component: Search
  },
  { path: '*', component: PageError }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
