import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import es from 'vuetify/src/locale/es'
import en from 'vuetify/src/locale/en'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { en, es },
    current: 'es'
  },
  icons: {
    iconfont: 'mdiSvg' // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  }
})
