















































































































































import { Component, Vue } from 'vue-property-decorator'
import { MenuIcon, LogInIcon } from 'vue-feather-icons'
import LanguageSelector from '@/components/LanguageSelector.vue'
import Footer from '@/components/Footer.vue'
import BootstrapAlert from '@/components/BootstrapAlert.vue'
import store from '@/store'

@Component({
  components: {
    MenuIcon,
    LogInIcon,
    Footer,
    LanguageSelector,
    BootstrapAlert
  }
})
export default class Login extends Vue {
  private user = '';
  private password = '';
  private fetching = false;
  private lastError: string | null = null;

  $refs!: {
    apiAddress: HTMLInputElement;
    apiProtocol: HTMLSelectElement;
  }

  async beforeMount () {
    // Fix . on address field for desktop version
    if (window.location.host === '.') {
      this.$store.commit('SET_API_ADDRESS', '')
    }

    // Handle iklab domain
    if (window.location.hostname.includes('iklab.cl')) {
      this.$store.commit('SET_API_ADDRESS', window.location.host)
      this.$store.commit('SET_API_PROTOCOL', window.location.protocol.replace(':', ''))
    }
  }

  async authenticate () {
    console.log('Authenticating...')
    this.fetching = true
    try {
      await store.dispatch('authenticateToAPI', {
        user: this.user,
        password: this.password
      })
    } catch (err) {
      console.log(err)
      this.lastError = err.toString()
      this.fetching = false
      return
    }
    this.fetching = false
    this.$router.push('/live')
  }

  get apiAddress () {
    return this.$store.getters.getApiAddress
  }

  get apiProtocol () {
    return this.$store.getters.getApiProtocol
  }

  updateApiAddress () {
    this.$store.commit('SET_API_ADDRESS', this.$refs.apiAddress.value)
  }

  updateApiProtocol () {
    this.$store.commit('SET_API_PROTOCOL', this.$refs.apiProtocol.value)
  }
}
