/* eslint-disable @typescript-eslint/camelcase */
export interface VideoUploadAdvancedOptions {
  imgquality_filter: {
    // (Default = true)
    enable: boolean;
    // BRISQUE,BLUR,BOTH (Default = BRISQUE)
    backend: string;
    // 20 ~ 80 (Default = 30)
    quality: number;
  };
  frontface_filter: {
    // (Default = true)
    enable: boolean;
    // DLIB,DLIS,DLIS+FAR,OLIS,OLIS+FAR (Default = DLIB)
    backend: string;
  };
  height_filter: {
    // (Default = true)
    enable: boolean;
    // 120 ~ 420 (Default = 200)
    ref_height: number;
  };
  facerecog_filter: {
    // (Default = true / No se puede deshabilitar)
    enable: boolean;
    // 70.0 ~ 99.0 (Default = 85.0)
    reliability: number;
    // 0.0 ~ 0.5 (Default = 0.5)
    cosine: number;
    // (Default = true)
    profiles_disabled: boolean;
  };
  // [key: string]: number | string
}

export const VideoUploadAdvancedOptionsDefaultGenerator = (): VideoUploadAdvancedOptions => {
  const g: VideoUploadAdvancedOptions = {
    imgquality_filter: {
      enable: true,
      backend: 'BRISQUE',
      quality: 40
    },
    frontface_filter: {
      enable: false,
      backend: 'DLIB'
    },
    height_filter: {
      enable: true,
      ref_height: 120
    },
    facerecog_filter: {
      enable: true,
      reliability: 90,
      cosine: 0.49,
      profiles_disabled: true
    }
  }
  return g
}
/* eslint-disable @typescript-eslint/camelcase */
